import { Box, Flex, Grid, Image, Card, CardBody, CardFooter, Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, { startTransition, useEffect, useState } from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import discount from '../artifacts/contracts/Discount.sol/Discount.json';
import token from '../artifacts/contracts/Token.sol/Token.json';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import '../Styles/Home.css';
import 'react-datepicker/dist/react-datepicker.css';
import bottomImg from '../Images/separator-top copy.svg';
import topImg from '../Images/separator-bottom copy.svg';
import axios from 'axios';
import Infomation from './Information'
import Twitter from '../Images/icons/twitter.png'
import Telegram from '../Images/icons/telegram.png'
import Website from '../Images/icons/globe.png'

import BaseLogo from '../Images/baseLogo.png'
import Bottleneck from 'bottleneck';
import bigInt from 'big-integer';
import Chart from '../Images/icons/chart.png'
import Leaderboard from './leaderboard';








const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const DiscountContractAddress = '0xaDf42C5371CD1fd28Cf82164D585E43ac7e5414d';
const UNISWAP_V2_FACTORY_ADDRESS = '0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6'; // Mainnet address
const UNISWAP_V2_FACTORY_ABI = ["function getPair(address tokenA, address tokenB) external view returns (address pair)"];
const apiKey = 'cxuZqfqcTi7h0lDURewlz7De3D6ggtww8hFX1Lnn'; // Replace with your actual API key

// Set up rate limiter
const limiter = new Bottleneck({
  minTime: 500, // Minimum time between requests in milliseconds
  maxConcurrent: 1 // Number of concurrent requests
});


const TopHeader = () => {


  const [connected, setConnected] = useState(false);

  const connectWallet = () => {
    setConnected(true);
  };

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
 
  const [infoValue, setInfoValue] = useState('info');
  const [showInfo, setShowInfo] = useState(true);
  const [showBuy, setShowBuy] = useState();
  const [showSetup, setShowSetup] = useState();
  const [showSearch, setSearchSetup] = useState();
  const [showAffilitate, setShowAffilitate] = useState();
  const [volume, setVolume] = useState();
  const [ethMade, setEthMade] = useState();
  const [active, setActive] = useState();
  const [closed, setClosed] = useState();
  const [baseFundName, setBaseFundTokenName] = useState();
  const [baseFundSym, setBaseFundTokenSym] = useState();
  const [unclaimedDiscounts, setUnclaimedDiscounts] = useState([]);


  const [discountName, setName] = useState();
  const [discountVolume, setvolume] = useState();
  const [discountRewardpercent, setRewardPercent] = useState();
  const [discountExpiry, setDiscountExpiry] = useState();
  const [discountRemaining, setRemaining] = useState();
  const [invalidIdError, setInvalidIdError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);



  const [funderHelpValue, setFunderHelpValue] = useState('starting');
  const [funderManageValue, setFunderManageValue] = useState('');
  const [funderManageFund, setfunderManageFund] = useState();
  const [funderManageManage, setfunderManageManage] = useState();

  const [showFundForm, setShowFundForm] = useState(false);
  const [showFunderStart, setShowFunderStart] = useState(true);
  const [showFunderClose, setShowFunderClose] = useState();
  const [fundersTokensId, setFundersTokensId] = useState([]);

  const [userDiscountsData, setUserDiscountsData] = useState([]);
  const [toggleFundRewardValue, setToggleFundRewardToggleValue] = useState('ETH');


  const [FundTokenName, setFundTokenName] = useState();
  const [FundTokenMainDec, setFundcalldec] = useState();
  const [fundTokensymbol, setfundTokensymbol] = useState();
  const [funddec, setFunddec] = useState();
  const [showFundContinueBtn, setShowBtns] = useState();
  const [showTotalDiscountsActive, setActiveDiscounts] = useState();
  const [showTotalEth, setTotalEth] = useState();
  const [funderManageCaFund, setfunderManageCaFund] = useState(); 
 

  const [showSearchSym, setFundSearchTokenSym] = useState();
  const [showSearchName, setFundSearchTokenName] = useState();





const [depositValueName, setDepositValueName] = useState();
const handleDepositChangeName = (e) => {
  setDepositValueName(e.target.value);
};

const [depositValueBonus, setDepositValueBonus] = useState();
const handleDepositChangeBonus = (e) => {
  setDepositValueBonus(e.target.value);
};
const [depositValueEthRwd, setDepositValueEthRwd] = useState();
const handleDepositChangeEthRwd = (e) => {
  setDepositValueEthRwd(e.target.value);
};
const [vestedInputValue, setVestedInputValue] = useState(''); //Vest Days Input
const handleVestedInputChange = (e) => {
  setVestedInputValue(e.target.value);
};




  const handleButtonClick = (id) => {
    if (toggleFundRewardValue === 'ETH') {
      handleEthButtonClick(id);
      console.log("ETH MINT")
    } else if (toggleFundRewardValue === 'TOKENS') {
      handleTokensButtonClick(id);
      console.log("TKN MINT")
    }
  };





//------------SET MINT FUNCTIONS HERE----------------------
  const handleEthButtonClick = (id) => {
    fundNFT(id)
    console.log('ETH button clicked ');
  };
//------------SET MINT FUNCTIONS HERE----------------------
  const handleTokensButtonClick = (id) => {
    //Handle button click for TOKENS
    handleTKNMintCoupon(id)
    console.log('TOKENS button clicked');
  };
//------------SET MINT FUNCTIONS HERE----------------------


  const handleFundRewardToggle = (value) => {
    setToggleFundRewardToggleValue(value);
  };


   
  const [AddQuickID, setDepositQickID] = useState();
  const handleDepositQuickID = (event) => {
    const newValue = event.target.value;
    setDepositQickID(newValue);

   
    fetchTokenData(newValue);
    
  };

  const [AddBuyETH, setDepositBuyETH] = useState();
    const handleDepositBuyETH = (e) => {
      setDepositBuyETH(e.target.value);
    };
  
const [AddBuySlip, setDepositBuySlip] = useState();
    const handleDepositBuySlip = (e) => {
      setDepositBuySlip(e.target.value);
    };


  const [funderDepositAddress, setDepositFunderAddress] = useState();
  const handleDepositFunderAddress = (e) => {
    setDepositFunderAddress(e.target.value);
  };

  const [depositValueFundContract, setDepositValueFundContract] = useState();
  const handleDepositFundContract = (event) => {
    const newValue = event.target.value;
    setDepositValueFundContract(newValue);
    // Call fetchTokenData function when the input value changes
    fetchFundTokenData(newValue);
    setShowBtns(true);
    
  };

  

  const [showBuyData, setShowBuyData] = useState();
 
  const [buyData, setBuyData] = useState(null);
  const [depositValueBuyContract, setDepositValueBuyContract] = useState();

  const handleDepositBuyContract = (e) => {
    const newValue = e.target.value;
    setDepositValueBuyContract(newValue);

  };

  const searchDepositBuy = () => {
    fetchBuyDiscounts(depositValueBuyContract)
   //fetchFundTokenData(depositValueBuyContract);
    setShowBuyData(true);
    setInvalidIdError(false); 
  }




  const [showSearchData, setShowSearchData] = useState(true);
  const [searchData, setSearchData] = useState(null);
  const [depositValueSearchContract, setDepositValueSearchContract] = useState("");
  const [invalidSearchError, setInvalidSearchError] = useState(false);
  
  const handleDepositSearchContract = (event) => {
    const newValue = event.target.value;
    setDepositValueSearchContract(newValue);
    fetchFundSearchTokenData(newValue)
    fetchSearchDiscounts(newValue);
    setShowSearchData(true);
    setInvalidSearchError(false);
  };

  const closeSearchContract = () => {
    setDepositValueSearchContract(null)
    setFundSearchTokenName(null)
    setFundSearchTokenSym(null)
    setShowSearchData(false);
  }

  




  const handleFundClose = () => {
    // Implement logic for "Continue Yes" button click
    setShowFundForm(false);
    setShowBtns(false);
    setfundTokensymbol();
    setFundcalldec();
    setFundTokenName()
    setBaseFundTokenName()
    setBaseFundTokenSym()
    setDepositValueFundContract()
    setFunderManageValue("")
    
    setfunderManageFund(false)
    setfunderManageManage(false)
    setfunderManageCaFund(false)
    
  };



  const handleClaimType = (type,id) => {
    if (type === 'eth') {
      setClaimVestedETH(id);
    } else if (type === 'tokens') {
      setClaimVestedTokens(id);
    }
  };







  const handleFunderManagecall = (id) => {
    handleFunderManage("manage")
    fetchDiscountInfo(id)
  };
  const handleFundContinueYes = () => {
    // Implement logic for "Continue Yes" button click
    setShowFundForm(true);
    setShowBtns(false);
    setfundTokensymbol();
    setFundcalldec();
    setfunderManageCaFund(false)
  };


  const handleInfo = (value) => {
    if (value === 'info') {
      setInfoValue(value)
      setShowInfo(true)
      setShowBuy(false)
      setShowSetup(false)
      setShowAffilitate(false)
      setSearchSetup(false)
      closeSearchContract()
      
      fetchMainData()

      console.log('info')
  

    }

    if (value === 'buy') {
      setInfoValue(value)
      setShowBuy(true)
      setShowInfo(false)
      setShowSetup(false)
      setShowAffilitate(false)
      setSearchSetup(false)
      closeSearchContract()
      fetchMainData()
      fetchUnclaimedDiscounts()
      setShowBuyInput(true)

      
      console.log('buy')
    }


    if (value === 'setup') {
      setInfoValue(value)
      setShowInfo(false)
      setShowBuy(false)
      setShowSetup(true)
      setShowAffilitate(false)
      setSearchSetup(false)
      closeSearchContract()
      fetchFundersIDData()
      fetchMainData()
      console.log('setup')
    }


    if (value === 'affiliate') {
      setInfoValue(value)
      setShowInfo(false)
      setShowBuy(false)
      setShowSetup(false)
      setShowAffilitate(true)
      setSearchSetup(false)
      fetchUserDiscounts();
      closeSearchContract()
      fetchUserData();
      fetchMainData()
      console.log('affiliate')
    }


    if (value === 'search') {
      setInfoValue(value)
      setSearchSetup(true)
      setShowInfo(false)
      setShowBuy(false)
      setShowSetup(false)
      setShowAffilitate(false)
      
   
      console.log('affiliate')
    }
  
  
  }

    const [showBuyinput, setShowBuyInput] = useState(true);



    const handleCloseBuy = () => {
      setShowBuyInput(true);
      setShowBuyData(false);
      setDepositBuySlip("")
      setDepositBuyETH("")
      setBuyData(null);
      setDepositValueBuyContract(null)

    }

    const handleSearchReset = () => {
      setShowBuyInput(true);
      setShowBuyData(false);
      setBuyData(null);
      setDepositValueBuyContract(null)
      

    }


   

    const handleFunder = (value) => {
      if (value === 'starting') {
        setFunderHelpValue(value)
        setShowFunderClose(false)
        setShowFunderStart(true)
       
        console.log('start')
    
  
      }
  
      if (value === 'closing') {
        setFunderHelpValue(value)
        setShowFunderClose(true)
        setShowFunderStart(false)
      

        console.log('closing')
      }

    }




    const handleFunderManage = (value, token) => {
      if (value === 'fund') {
        setFunderManageValue(value)
        setfunderManageFund(true)
        setfunderManageManage(false)
        setfunderManageCaFund(true)
        
       
        console.log('start')
    
  
      }
  
      if (value === 'manage') {
        setFunderManageValue(value)
        setfunderManageFund(false)
        setfunderManageManage(true)
        setfunderManageCaFund(false)
        setShowFundForm(false);
        setShowBtns(false);
        setfundTokensymbol();
        setFundcalldec();
        setFundTokenName()
        setBaseFundTokenName()
        setBaseFundTokenSym()
        setDepositValueFundContract()
        
      

        console.log('closing')
      }

    }



    async function setClaimVestedETH(id) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const transaction = await contract.withdrawVestedEth(id);
        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: 'Claim is pending. Please wait...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Claimed Successfully',
        });
        fetchUnclaimedDiscounts()
      } catch (error) {
        console.error('Transaction failed:', error);
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }


    async function setRevoke(id) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const transaction = await contract.revoke(id);
        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: 'Revoke is pending. Please wait...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: `Revoked Dealix #${id} Successfully`,
        });
        fetchUserDiscounts()
      } catch (error) {
        console.error('Transaction failed:', error);
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }


    async function setClaimVestedTokens(id) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const transaction = await contract.withdrawVestedTokens(id);
        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: 'Claim is pending. Please wait...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Claimed Successfully',
        });
        fetchUnclaimedDiscounts()
      } catch (error) {
        console.error('Transaction failed:', error);
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }


    async function fetchSearchDiscounts(contractAddress) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider); // Adjust walletProvider as needed
        const signer = await ethersProvider.getSigner();
        const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const discountIds = await discountContract.searchDiscountsByContract(contractAddress);
    
        const discountDataPromises = discountIds.map(async (discountId) => {
          const voteData = await discountContract.discounts(discountId);
          const contractAddress = voteData[3].toString();
          const funderAddress = voteData[1];
          const Vestingtype = voteData[10].toString();
          let tokenName = "Ready To Setup";
          let tokenLogo = "";
          let tokenTwitter = "";
          let tokenWebsite = "";
          let tokenTelegram = "";
          let tokenColor = "#14ebff"; // Default color
          let tokenMc = "";
          let tokenChart = "";
          let price5min = "";
          let price1hr = "";
          let price6hr = "";
          let price24hr = "";
          let color5min = "";
          let color1hr = "";
          let color6hr = "";
          let color24hr = "";
          let vestingType = "";
    
          let buyTax = "N/A";
          let sellTax = "N/A";
          let scam = "N/A";
          let isOpenSource = "N/A";
          let isHoneypot = "N/A";
          let isMintable = "N/A";
          let isProxy = "N/A";
          let slippageModifiable = "N/A";
          let isBlacklisted = "N/A";
          let isContractRenounced = "N/A";
          let renouncedColor = "#ff2a2a";
          let isHoneypotColor = "#14ebff";
          let buyTaxColor = "#14ebff";
          let sellTaxColor = "#14ebff";
    
          if (Vestingtype === "true") {
            vestingType = "ETH";
          } else {
            vestingType = "Tokens";
          }
    
          if (contractAddress === "0x0000000000000000000000000000000000000000") {
            setInvalidSearchError(true); // Set invalid ID error state
            return; // Exit function early if invalid ID
          }
    
          if (contractAddress !== "0x0000000000000000000000000000000000000000") {
            const TempContract = new Contract(contractAddress, token.abi, signer);
            const newName = await TempContract.name();
    
            tokenName = newName;
            const tokenInfo = await fetchTokenData(contractAddress); // Fetch the token data
            tokenLogo = tokenInfo.tokenLogo;
            tokenTelegram = tokenInfo.tokenTelegram;
            tokenTwitter = tokenInfo.tokenTwitter;
            tokenWebsite = tokenInfo.tokenWebsite;
            tokenMc = tokenInfo.marketCap;
            tokenColor = "#8de4ff";
            tokenChart = tokenInfo.tokenChart;
            price5min = tokenInfo.price5min;
            price1hr = tokenInfo.price1hr;
            price6hr = tokenInfo.price6hr;
            price24hr = tokenInfo.price24hr;
            color5min = tokenInfo.color5min;
            color1hr = tokenInfo.color1hr;
            color6hr = tokenInfo.color6hr;
            color24hr = tokenInfo.color24hr;
    
            buyTax = tokenInfo.buyTax;
            sellTax = tokenInfo.sellTax;
            scam = tokenInfo.scam.toUpperCase();
            isOpenSource = tokenInfo.isOpenSource.toUpperCase();
            isHoneypot = tokenInfo.isHoneypot.toUpperCase();
            isMintable = tokenInfo.isMintable.toUpperCase();
            isProxy = tokenInfo.isProxy.toUpperCase();
            slippageModifiable = tokenInfo.slippageModifiable.toUpperCase();
            isBlacklisted = tokenInfo.isBlacklisted.toUpperCase();
            isContractRenounced = tokenInfo.isContractRenounced.toUpperCase();
    
            renouncedColor = isContractRenounced === "YES" ? "#01fc01" : "#ff2a2a";
            isHoneypotColor = isHoneypot === "YES" ? "#ff2a2a" : "#01fc01";
    
            if (parseFloat(buyTax) === 0) {
              buyTaxColor = "#01fc01";
            } else if (parseFloat(buyTax) > 0 && parseFloat(buyTax) < 5) {
              buyTaxColor = "#ffd600";
            } else if (parseFloat(buyTax) >= 5) {
              buyTaxColor = "#ff2a2a";
            }
    
            if (parseFloat(sellTax) === 0) {
              sellTaxColor = "#01fc01";
            } else if (parseFloat(sellTax) > 0 && parseFloat(sellTax) < 5) {
              sellTaxColor = "#ffd600";
            } else if (parseFloat(sellTax) >= 5) {
              sellTaxColor = "#ff2a2a";
            }
          } else {
            tokenName = "Ready To Assign";
            tokenColor = "Yellow"; // Default color
          }
    
          return {
            tokenId: "Dealix ID # " + discountId.toString(),
            baseToken: discountId,
            owner: voteData[0],
            funder: voteData[1],
            name: voteData[2],
            contractAddress: voteData[3],
            bonusPercentage: voteData[4].toString() + " %",
            expirationTime: voteData[5],
            startingRewards: voteData[6],
            tknVestTime: voteData[7].toString() + " Hours",
            rewardsSent: voteData[8],
            volume: parseFloat(formatUnits(voteData[9], 18)).toFixed(3) + " ETH",
            tknVestType: vestingType,
            tokenName: tokenName,
            tokenLogo: tokenLogo,
            tokenTG: tokenTelegram,
            tokenTW: tokenTwitter,
            tokenWS: tokenWebsite,
            tokenMC: tokenMc,
            tokenColor: tokenColor,
            chart: tokenChart,
            price5min: price5min,
            price1hr: price1hr,
            price6hr: price6hr,
            price24hr: price24hr,
            color5min: color5min,
            color1hr: color1hr,
            color6hr: color6hr,
            color24hr: color24hr,
            buyTax: buyTax,
            sellTax: sellTax,
            scam: scam,
            isOpenSource: isOpenSource,
            isHoneypot: isHoneypot,
            isMintable: isMintable,
            isProxy: isProxy,
            slippageModifiable: slippageModifiable,
            isBlacklisted: isBlacklisted,
            isContractRenounced: isContractRenounced,
            renouncedColor: renouncedColor,
            isHoneypotColor: isHoneypotColor,
            buyTaxColor: buyTaxColor,
            sellTaxColor: sellTaxColor
          };
        });
    
        const discountData = await Promise.all(discountDataPromises);
        setSearchData(discountData);
        setShowSearchData(true); // Set to true to display fetched data
      } catch (err) {
        console.log(err.message);
        setSearchData(null); // Clear data on error
        setShowSearchData(false); // Reset to false on error
      }
    }
    






    async function handleTKNMintCoupon(depositValueID) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const CouponContract = new Contract(depositValueFundContract, token.abi, signer);

      const depositValueInWei = parseUnits(depositValueEthRwd.toString(), FundTokenMainDec);

      console.log(depositValueInWei)
      console.log(depositValueFundContract)
      const depositValueBonusInWei = depositValueBonus.toString();
      const ethRewards = false;
      const name = await CouponContract.name();


      const Approvetransaction = await CouponContract.approve(DiscountContractAddress, depositValueInWei);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Approval is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
      const ApproveReceipt = await Approvetransaction.wait();

      console.log('Allowance Approval successful');
     


  
      const transaction = await contract.fundNFT(
        depositValueID, // COUPON ID
        depositValueName, // NAME
        depositValueBonusInWei, // % Rewards
        depositValueFundContract, // CONTRACT0
        depositValueInWei, // DEPOSIT VALUE
        vestedInputValue,
        ethRewards
       
      );

        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: `Dealix Creation for ${name} Is Pending. Please wait...`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text:  `Dealix ${depositValueID} Created for ${name}.`,
        });
        fetchUserDiscounts();
      } catch (error) {
        console.error('Transaction failed:', error);
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }






    async function fundNFT(depositValueID) {
      try {
        // Create ethers provider and signer
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
        const CouponContract = new Contract(depositValueFundContract, token.abi, signer);
    
        // Convert values to appropriate units
        const depositValueInWei = parseUnits(depositValueEthRwd.toString(), 18);
        const depositValueBonusInWei = depositValueBonus.toString();
        const ethRewards = true;
        const name = await CouponContract.name();
    
  

        
    
        // Initiate the transaction
        const transaction = await contract.fundNFT(
          depositValueID, // COUPON ID
          depositValueName, // NAME
          depositValueBonusInWei, // % Rewards
          depositValueFundContract, // CONTRACT0
          depositValueInWei, // DEPOSIT VALUE
          vestedInputValue,
          ethRewards,
          { value: depositValueInWei } // Transaction value in Wei
        );
    
        // Show pending alert
        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: `Dealix Creation for ${name} Is Pending. Please wait...`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        // Wait for transaction confirmation
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
    
        // Close pending alert and show success alert
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text:  `Dealix ${depositValueID} Created for ${name}.`,
        });
    
        // Fetch user discounts after successful transaction
        fetchFundersIDData();
      } catch (error) {
        console.error('Transaction failed:', error);
    
        // Show error alert
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }



    async function fetchFundTokenData(depositValueContract) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(depositValueContract, token.abi, signer);
  
        // Initialize Uniswap V2 factory contract
        const uniswapV2Factory = new Contract(UNISWAP_V2_FACTORY_ADDRESS, UNISWAP_V2_FACTORY_ABI, signer);
    
        // WETH address on Uniswap V2
        const WETH_ADDRESS = '0x4200000000000000000000000000000000000006';
    
        // Check if the pair (liquidity pool) exists
        const pairAddress = await uniswapV2Factory.getPair(WETH_ADDRESS, depositValueContract);
        const flippairAddress = await uniswapV2Factory.getPair(depositValueContract, WETH_ADDRESS);

        if (pairAddress === '0x0000000000000000000000000000000000000000' && flippairAddress === '0x0000000000000000000000000000000000000000') {
          throw new Error("DEALIX currently only support tokens with a V2 liquidity pool, Please Return Dealix If Needed");
          handleFundClose();
        }
    
        if (signer) {
          const tokenName = await contract.name();
          const tokenDecimals = await contract.decimals();
          const tokenSymbol = await contract.symbol();
    
          const name = "Token Name: " + tokenName;
          const sym = "Token Ticker: " + tokenSymbol;
          const decimals = "Token Decimals: " + tokenDecimals;
    
          setFundTokenName(name);
          setBaseFundTokenName(tokenName);
          setBaseFundTokenSym(tokenSymbol);
          setFunddec(decimals);
          setFundcalldec(tokenDecimals);
          setfundTokensymbol(sym);
          setShowBtns(true);
        }
      } catch (err) {
        console.log(err.message);
    
        // Handle error in the UI
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.message,
        });
      }
    }
    


      async function fetchFundSearchTokenData(depositValueSearchContract) {
  
        try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(depositValueSearchContract, token.abi, signer);
    
          if (signer)  {
            const tokenName = await contract.name();
            const tokenSymbol = await contract.symbol();
  
            const name = tokenName;
            const sym = tokenSymbol;
            
  
            setFundSearchTokenName(tokenName);
            setFundSearchTokenSym(tokenSymbol);
          
          }
        }
        catch (err) {
          console.log(err.message);
        }
      }

      async function fetchMainData() {
  
        try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
          if (signer)  {
            const totalDiscounts = await contract.getActiveDiscountIdsLength();
            const totalEth = await contract.totalVolume();
            const newTotalDiscounts = totalDiscounts.toString() + " Deals";
            const NewETH = parseFloat(formatUnits(totalEth)).toFixed(3) + " ETH";
            
           
            setActiveDiscounts(newTotalDiscounts)
            setTotalEth(NewETH)
          }
        }
        catch (err) {
          console.log(err.message);
        }
      }
    


  useEffect(() => {
    fetchMainData()
  }, [address])

  async function setFunderdiscount(id) {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const transaction = await contract.establishFunder(id,funderDepositAddress);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Set Funder is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
  
      const receipt = await transaction.wait();
      console.log('Transaction successful:', receipt);
      Swal.close(); // Close the pending alert
      Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Funder Set Successfully, The Address Now Has Control Of The Deal',
      });
      fetchUserDiscounts()
    } catch (error) {
      console.error('Transaction failed:', error);
  
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
      });
    }
  }



  async function setSelfFunderdiscount(id) {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const transaction = await contract.establishFunder(id,address);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Self Funding is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
  
      const receipt = await transaction.wait();
      console.log('Transaction successful:', receipt);
      Swal.close(); // Close the pending alert
      Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Self Funding Set Successfully, Check Start & Set-up Page To Set-Up',
      });
      fetchUserDiscounts()
    } catch (error) {
      console.error('Transaction failed:', error);
  
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
      });
    }
  }









  const fetchUnclaimedDiscounts = async () => {
    try {
      // Set up ethers provider and signer
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      // Fetch discount IDs
      const discountIds = await discountContract.viewUserDiscountIds(signer);
  
      // Initialize an array to store unclaimed discounts
      const unclaimedDiscountsData = [];

      let claimType = "";
  
      // Loop through discount IDs and fetch user info
      for (let i = 0; i < discountIds.length; i++) {
        const tokenId = discountIds[i];
        const userInfo = await discountContract.viewUserInfo(signer, tokenId);
  
        // Check if the status is not "Claimed"
        if (userInfo[7] !== "Claimed") {
          const ID = userInfo[1].toString();
          const tokenContract = new Contract(ID, token.abi, signer);
          const tokenName = await tokenContract.name();
          const tokenTicker = await tokenContract.symbol();
          const tokenDecimals = await tokenContract.decimals();
          
          // Ensure vestingAmount is a string
          let vestingAmount;
          if (userInfo[2].toString() === "0") {
            vestingAmount = formatUnits(userInfo[3].toString(), 18) + " ETH";
            claimType = "eth";
          } else {
            vestingAmount = formatUnits(userInfo[2].toString(), tokenDecimals) + " " + tokenTicker;
            claimType = "tokens";
          }
          
          // Convert unlockTime to a JavaScript Date object and then to a locale string date
          const unlockUnix = parseInt(userInfo[4].toString(), 10); // Convert to integer
          const unlockTime = new Date(unlockUnix * 1000).toLocaleString();
          
          // Convert bought and holdings to formatted strings
          const holdingsAmount = parseFloat(formatUnits(userInfo[6], tokenDecimals)).toLocaleString() + " " + tokenName;
          
          // Check if the current time is greater than the unlock time
          const canClaim = Date.now() / 1000 > unlockUnix;
  
          const discountData = {
            tokenId: tokenId.toString(),
            contractAddress: userInfo[1].toString(),
            vestingAmount: vestingAmount,
            unlockTime: unlockTime,
            holdingsAmount: holdingsAmount,
            status: userInfo[7],
            tokenName: tokenName,
            tokenTicker: tokenTicker,
            tokenDecimals: tokenDecimals.toString(),
            canClaim: canClaim,
            claimType: claimType
          };
          unclaimedDiscountsData.push(discountData);
        }
      }
  
      // Update state with unclaimed discounts data
      setUnclaimedDiscounts(unclaimedDiscountsData);
    } catch (error) {
      console.error('Error fetching unclaimed Dealix:', error);
    }
  };
  








  async function Mintdiscount() {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const transaction = await contract.mintDiscount();
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Dealix creation is pending. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
  
      const receipt = await transaction.wait();
      console.log('Transaction successful:', receipt);
      Swal.close(); // Close the pending alert
      Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Created Successfully',
      });
      fetchUserDiscounts();
    } catch (error) {
      console.error('Transaction failed:', error);
  
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
      });
    }
  }


  async function RequestClose(id) {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const transaction = await contract.requestCloseDiscount(id);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Requesting Close. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
  
      const receipt = await transaction.wait();
      console.log('Transaction successful:', receipt);
      Swal.close(); // Close the pending alert
      Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Closed Request Successful',
      });
      fetchUserDiscounts();
    } catch (error) {
      console.error('Transaction failed:', error);
  
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
      });
    }
  }



  async function CloseDiscount(id) {
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const transaction = await contract.closeDiscount(id);
      Swal.fire({
        icon: 'info',
        title: 'Transaction Pending',
        text: 'Closing Dealix. Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });
  
      const receipt = await transaction.wait();
      console.log('Transaction successful:', receipt);
      Swal.close(); // Close the pending alert
      Swal.fire({
        icon: 'success',
        title: 'Transaction Successful',
        text: 'Closed Successful',
      });
      fetchUserDiscounts();
    } catch (error) {
      console.error('Transaction failed:', error);
  
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason || error.message,
      });
    }
  }





  async function fetchBuyDiscounts(newValue) {
    try {
      setLoadingData(true)
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(DiscountContractAddress, discount.abi, signer);
  
      const voteData = await contract.discounts(newValue);
  
      if (!voteData || voteData.length < 11) {
        throw new Error("Invalid vote data received from the contract.");
      }
  
      const contractAddress = voteData[3].toString();
      const Vestingtype = voteData[10].toString();
      let tokenName = "Ready To Setup";
      let tokenLogo = "";
      let tokenTwitter = "";
      let tokenWebsite = "";
      let tokenTelegram = "";
      let tokenColor = "#14ebff"; // Default color
      let tokenMc = "";
      let tokenChart = "";
      let price5min = "";
      let price1hr = "";
      let price6hr = "";
      let price24hr = "";
      let color5min = "";
      let color1hr = "";
      let color6hr = "";
      let color24hr = "";
      let vestingType = Vestingtype === "true" ? "ETH" : "Tokens";
  
      let buyTax = "N/A";
      let sellTax = "N/A";
      let scam = "N/A";
      let isOpenSource = "N/A";
      let isHoneypot = "N/A";
      let isMintable = "N/A";
      let isProxy = "N/A";
      let slippageModifiable = "N/A";
      let isBlacklisted = "N/A";
      let isContractRenounced = "N/A";
      let renouncedColor = "#ff2a2a";
      let isHoneypotColor = "#14ebff";
      let buyTaxColor = "#14ebff";
      let sellTaxColor = "#14ebff";
  
      if (contractAddress !== "0x0000000000000000000000000000000000000000") {
        const TempContract = new Contract(contractAddress, token.abi, signer);
        const newName = await TempContract.name();
  
        tokenName = newName.toString();
        const tokenInfo = await fetchTokenData(contractAddress); // Fetch the token data
        tokenLogo = tokenInfo.tokenLogo;
        tokenTelegram = tokenInfo.tokenTelegram;
        tokenTwitter = tokenInfo.tokenTwitter;
        tokenWebsite = tokenInfo.tokenWebsite;
        tokenMc = tokenInfo.marketCap;
        tokenColor = "#8DE4FF";
        tokenChart = tokenInfo.tokenChart;
        price5min = tokenInfo.price5min;
        price1hr = tokenInfo.price1hr;
        price6hr = tokenInfo.price6hr;
        price24hr = tokenInfo.price24hr;
        color5min = tokenInfo.color5min;
        color1hr = tokenInfo.color1hr;
        color6hr = tokenInfo.color6hr;
        color24hr = tokenInfo.color24hr;
  
        buyTax = tokenInfo.buyTax;
        sellTax = tokenInfo.sellTax;
        scam = tokenInfo.scam.toUpperCase();
        isOpenSource = tokenInfo.isOpenSource.toUpperCase();
        isHoneypot = tokenInfo.isHoneypot.toUpperCase();
        isMintable = tokenInfo.isMintable.toUpperCase();
        isProxy = tokenInfo.isProxy.toUpperCase();
        slippageModifiable = tokenInfo.slippageModifiable.toUpperCase();
        isBlacklisted = tokenInfo.isBlacklisted.toUpperCase();
        isContractRenounced = tokenInfo.isContractRenounced.toUpperCase();
  
        renouncedColor = isContractRenounced === "YES" ? "#01fc01" : "#ff2a2a";
        isHoneypotColor = isHoneypot === "YES" ? "#ff2a2a" : "#01fc01";
  
        if (parseFloat(buyTax) === 0) {
          buyTaxColor = "#01fc01";
        } else if (parseFloat(buyTax) > 0 && parseFloat(buyTax) < 5) {
          buyTaxColor = "#ffd600";
        } else if (parseFloat(buyTax) >= 5) {
          buyTaxColor = "#ff2a2a";
        }
  
        if (parseFloat(sellTax) === 0) {
          sellTaxColor = "#01fc01";
        } else if (parseFloat(sellTax) > 0 && parseFloat(sellTax) < 5) {
          sellTaxColor = "#ffd600";
        } else if (parseFloat(sellTax) >= 5) {
          sellTaxColor = "#ff2a2a";
        }
      } else {
        console.log("Invalid contract address");
        setInvalidIdError(true); // Set invalid ID error state
        return; // Exit function early if invalid ID
      }
  
      const data = {
        tokenId: "Dealix ID # " + newValue,
        baseToken: newValue,
        name: voteData[2],
        contractAddress: voteData[3],
        bonusPercentage: voteData[4].toString() + " %",
        expirationTime: voteData[5],
        startingRewards: voteData[6],
        tknVestTime: voteData[7].toString() + " Hours",
        rewardsSent: voteData[8],
        volume: parseFloat(formatUnits(voteData[9], 18)).toFixed(3) + " ETH",
        ethRewards: vestingType,
        tokenName: tokenName,
        tokenLogo: tokenLogo,
        tokenTG: tokenTelegram,
        tokenTW: tokenTwitter,
        tokenWS: tokenWebsite,
        tokenMC: tokenMc,
        tokenColor: tokenColor,
        chart: tokenChart,
        price5min: price5min,
        price1hr: price1hr,
        price6hr: price6hr,
        price24hr: price24hr,
        color5min: color5min,
        color1hr: color1hr,
        color6hr: color6hr,
        color24hr: color24hr,
        buyTax: buyTax,
        sellTax: sellTax,
        scam: scam,
        isOpenSource: isOpenSource,
        isHoneypot: isHoneypot,
        isMintable: isMintable,
        isProxy: isProxy,
        slippageModifiable: slippageModifiable,
        isBlacklisted: isBlacklisted,
        isContractRenounced: isContractRenounced,
        renouncedColor: renouncedColor,
        isHoneypotColor: isHoneypotColor,
        buyTaxColor: buyTaxColor,
        sellTaxColor: sellTaxColor
      };
  
      setShowBuyInput(false);
      setBuyData(data);
      setShowBuyData(true); // Set to true to display fetched data
      setLoadingData(false)
    } catch (err) {
      console.error(err);
      setBuyData(null); // Clear data on error
      setShowBuyData(false); // Reset to false on error
      
    }
  }
  

































async function fetchUserDiscounts() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");
        const tokenIds = await discountContract.walletOfOwner(signer);
        const activeRemoveVotesPromises = tokenIds.map(async (tokenId) => {
          const voteData = await discountContract.discounts(tokenId);
          const contractAddress = voteData[3].toString();
          const funderAddress = voteData[1].toString();
          let canInteract = true;
          let canRevoke = false;

          let tokenName = "Ready To Setup";
          let tokenLogo = "";
          let tokenTwitter = "";
          let tokenWebsite = "";
          let tokenTelegram = "";
          let tokenColor = "#14ebff"; // Default color
          let tokenMc = "";
          let tokenChart = "";
          let price5min = "";
          let price1hr = "";
          let price6hr = "";
          let price24hr = "";
          let color5min = "";
          let color1hr = "";
          let color6hr = "";
          let color24hr = "";
          let funded = "";
          let buyTax = "N/A";
          let sellTax = "N/A";
          let scam = "N/A";
          let isOpenSource = "N/A";
          let isHoneypot = "N/A";
          let isMintable = "N/A";
          let isProxy = "N/A";
          let slippageModifiable = "N/A";
          let isBlacklisted = "N/A";
          let isContractRenounced = "N/A";
          let renouncedColor = "#ff2a2a";
          let isHoneypotColor = "#14ebff";
          let buyTaxColor = "#14ebff";
          let sellTaxColor = "#14ebff";

          if (funderAddress !== "0x0000000000000000000000000000000000000000" && contractAddress === "0x0000000000000000000000000000000000000000") {
            tokenName = "Funder Has Control";
            tokenColor = "#ffa63e"; 
            canInteract = false;
            canRevoke = true;
          }

          if (contractAddress !== "0x0000000000000000000000000000000000000000" && funderAddress !== "0x0000000000000000000000000000000000000000") {
            const TempContract = new Contract(contractAddress, token.abi, signer);
            const newName = await TempContract.name();
            canInteract = false;
            canRevoke = false;
           

            tokenName = newName;
            const tokenInfo = await fetchTokenData(contractAddress); // Fetch the token data
            tokenLogo = tokenInfo.tokenLogo;
            tokenTelegram = tokenInfo.tokenTelegram;
            tokenTwitter = tokenInfo.tokenTwitter;
            tokenWebsite = tokenInfo.tokenWebsite;
            tokenMc = tokenInfo.marketCap;
            tokenColor = "#8DE4FF";
            tokenChart = tokenInfo.tokenChart;
            price5min = tokenInfo.price5min;
            price1hr = tokenInfo.price1hr;
            price6hr = tokenInfo.price6hr;
            price24hr = tokenInfo.price24hr;
            color5min = tokenInfo.color5min;
            color1hr = tokenInfo.color1hr;
            color6hr = tokenInfo.color6hr;
            color24hr = tokenInfo.color24hr;
            funded = "true";
            buyTax = tokenInfo.buyTax;
            sellTax = tokenInfo.sellTax;
            scam = tokenInfo.scam.toUpperCase();
            isOpenSource = tokenInfo.isOpenSource.toUpperCase();
            isHoneypot = tokenInfo.isHoneypot.toUpperCase();
            isMintable = tokenInfo.isMintable.toUpperCase();
            isProxy = tokenInfo.isProxy.toUpperCase();
            slippageModifiable = tokenInfo.slippageModifiable.toUpperCase();
            isBlacklisted = tokenInfo.isBlacklisted.toUpperCase();
            isContractRenounced = tokenInfo.isContractRenounced.toUpperCase();

            renouncedColor = isContractRenounced === "YES" ? "#01fc01" : "#ff2a2a";
            isHoneypotColor = isHoneypot === "YES" ? "#ff2a2a" : "#01fc01";

            if (parseFloat(buyTax) === 0) {
              buyTaxColor = "#01fc01";
            } else if (parseFloat(buyTax) > 0 && parseFloat(buyTax) < 5) {
              buyTaxColor = "#ffd600";
            } else if (parseFloat(buyTax) >= 5) {
              buyTaxColor = "#ff2a2a";
            }

            if (parseFloat(sellTax) === 0) {
              sellTaxColor = "#01fc01";
            } else if (parseFloat(sellTax) > 0 && parseFloat(sellTax) < 5) {
              sellTaxColor = "#ffd600";
            } else if (parseFloat(sellTax) >= 5) {
              sellTaxColor = "#ff2a2a";
            }
          }
 

          return {
            tokenId: "Dealix ID # " + tokenId.toString(),
            baseToken: tokenId,
            owner: voteData[0],
            funder: voteData[1],
            name: voteData[2],
            contractAddress: voteData[3],
            bonusPercentage: voteData[4],
            expirationTime: voteData[5],
            startingRewards: voteData[6],
            tknVestTime: voteData[7],
            rewardsSent: voteData[8],
            volume: voteData[9],
            ethRewards: voteData[10],
            tokenName: tokenName,
            tokenLogo: tokenLogo,
            tokenTG: tokenTelegram,
            tokenTW: tokenTwitter,
            tokenWS: tokenWebsite,
            tokenMC: tokenMc,
            tokenColor: tokenColor,
            chart: tokenChart,
            price5min: price5min,
            price1hr: price1hr,
            price6hr: price6hr,
            price24hr: price24hr,
            color5min: color5min,
            color1hr: color1hr,
            color6hr: color6hr,
            color24hr: color24hr,
            isFunded: funded,
            buyTax: buyTax,
            sellTax: sellTax,
            scam: scam,
            isOpenSource: isOpenSource,
            isHoneypot: isHoneypot,
            isMintable: isMintable,
            isProxy: isProxy,
            slippageModifiable: slippageModifiable,
            isBlacklisted: isBlacklisted,
            isContractRenounced: isContractRenounced,
            renouncedColor: renouncedColor,
            isHoneypotColor: isHoneypotColor,
            buyTaxColor: buyTaxColor,
            sellTaxColor: sellTaxColor,
            canInteract: canInteract,
            canRevoke: canRevoke
          };
        });

        const votesData = await Promise.all(activeRemoveVotesPromises);
        setUserDiscountsData(votesData); // Ensure `newestTokensId` state is updated with complete data
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setUserDiscountsData(["No Deals Found..."]);
  }
}

  


const fetchTokenData = async (contractAddress) => {
  const url = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}`;
  const marketCapUrl = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/info`;
  const priceUrl = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/price`;
  const chart = `https://www.dextools.io/app/en/eth/pair-explorer/${contractAddress}`;
  const auditUrl = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/audit`;

  // Function to fetch data from API with retry logic
  const fetchWithRetry = async (url) => {
    let attempts = 0;
    const maxAttempts = 5;
    const retryDelay = 10000; // 10 seconds retry delay

    while (attempts < maxAttempts) {
      try {
        const response = await axios.get(url, {
          headers: {
            'x-api-key': apiKey, // Replace with your API key
            'accept': 'application/json'
          }
        });
        return response.data.data;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          attempts++;
          const delay = retryDelay * attempts;
          console.warn(`Rate limit exceeded. Retrying in ${delay} ms...`);
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          throw error;
        }
      }
    }
    throw new Error('Max retry attempts reached');
  };

  try {
    // Fetch data from URLs
    const [data, marketCapData, priceData, auditData] = await Promise.all([
      fetchWithRetry(url),
      fetchWithRetry(marketCapUrl),
      fetchWithRetry(priceUrl),
      fetchWithRetry(auditUrl)
    ]);

    // Extract relevant data fields
    const tokenLogo = data.logo || '';
    const tokenTelegram = data.socialInfo?.telegram || '';
    const tokenTwitter = data.socialInfo?.twitter || '';
    const tokenWebsite = data.socialInfo?.website || '';

    // Calculate prices and format to 2 decimal places
    const price5min = priceData.variation5m ? priceData.variation5m.toFixed(2) : '0.00';
    const price1hr = priceData.variation1h ? priceData.variation1h.toFixed(2) : '0.00';
    const price6hr = priceData.variation6h ? priceData.variation6h.toFixed(2) : '0.00';
    const price24hr = priceData.variation24h ? priceData.variation24h.toFixed(2) : '0.00';

    // Determine colors based on price variations
    let color5min = '';
    let color1hr = '';
    let color6hr = '';
    let color24hr = '';

    if (parseFloat(price5min) <= 0) {
      color5min = '#ff2a2a';
    } else if (parseFloat(price5min) < 2) {
      color5min = '#ffd600';
    } else {
      color5min = '#01fc01';
    }

    if (parseFloat(price1hr) <= 0) {
      color1hr = '#ff2a2a';
    } else if (parseFloat(price1hr) < 4) {
      color1hr = '#ffd600';
    } else {
      color1hr = '#01fc01';
    }

    if (parseFloat(price6hr) <= 0) {
      color6hr = '#ff2a2a';
    } else if (parseFloat(price6hr) < 4) {
      color6hr = '#ffd600';
    } else {
      color6hr = '#01fc01';
    }

    if (parseFloat(price24hr) <= 0) {
      color24hr = '#ff2a2a';
    } else if (parseFloat(price24hr) < 4) {
      color24hr = '#ffd600';
    } else {
      color24hr = '#01fc01';
    }

    // Calculate market cap and format if available
    let marketCap = marketCapData.fdv || 0;
    let formattedMarketCap = '';

    if (marketCap === 0) {
      formattedMarketCap = 'Not Available';
    } else {
      formattedMarketCap = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(marketCap);
    }

    // Extract audit data
    const buyTax = auditData.buyTax?.max !== undefined ? String(auditData.buyTax.max) : 'N/A';
    const sellTax = auditData.sellTax?.max !== undefined ? String(auditData.sellTax.max) : 'N/A';
    const scam = auditData.isPotentiallyScam || '';
    const isOpenSource = auditData.isOpenSource || '';
    const isHoneypot = auditData.isHoneypot || '';
    const isMintable = auditData.isMintable || '';
    const isProxy = auditData.isProxy || '';
    const slippageModifiable = auditData.slippageModifiable || '';
    const isBlacklisted = auditData.isBlacklisted || '';
    const isContractRenounced = auditData.isContractRenounced || '';

    // Return the extracted and calculated data
    return {
      tokenLogo,
      tokenTelegram,
      tokenTwitter,
      tokenWebsite,
      marketCap: formattedMarketCap,
      tokenChart: chart,
      price5min,
      price1hr,
      price6hr,
      price24hr,
      color5min,
      color1hr,
      color6hr,
      color24hr,
      buyTax,
      sellTax,
      scam,
      isOpenSource,
      isHoneypot,
      isMintable,
      isProxy,
      slippageModifiable,
      isBlacklisted,
      isContractRenounced
    };

  } catch (error) {
    console.error('Error fetching token data:', error);
    return {
      tokenLogo: '',
      tokenTelegram: '',
      tokenTwitter: '',
      tokenWebsite: '',
      marketCap: '',
      tokenChart: '',
      price5min: '',
      price1hr: '',
      price6hr: '',
      price24hr: '',
      color5min: '',
      color1hr: '',
      color6hr: '',
      color24hr: '',
      buyTax: 'N/A',
      sellTax: 'N/A',
      scam: '',
      isOpenSource: '',
      isHoneypot: '',
      isMintable: '',
      isProxy: '',
      slippageModifiable: '',
      isBlacklisted: '',
      isContractRenounced: ''
    };
  }
};
























  
  
  
  async function fetchUserData() {
    if (address) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);
      try {

        if (signer) {
          const affiliate = await discountContract.affiliates(signer);
      
          // Using ethers.js utilities to format the values
          const volume = parseFloat(formatUnits(affiliate[1], 18)).toFixed(3) + " ETH";
          const ethMade = parseFloat(formatUnits(affiliate[2], 18)).toFixed(3) + " ETH";
          
          
         
          
          setVolume(volume);
          setEthMade(ethMade);
         
        }
        
      }
      catch (err) {
        console.log(err.message);
      }
    }
}



async function fetchFundersIDData() {
  if (address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);

    try {
      if (signer) {
        console.log("Fetching");
        const tokenIds = await discountContract.getTokenIds(signer);
        const activeRemoveVotesPromises = tokenIds.map(async (tokenId) => {
          const voteData = await discountContract.discounts(tokenId);
          const contractAddress = voteData[3].toString();
          const funderAddress = voteData[1];
          let tokenName = "Ready To Setup";
          let tokenLogo = "";
          let tokenTwitter = "";
          let tokenWebsite = "";
          let tokenTelegram = "";
          let tokenColor = "#14ebff"; // Default color
          let tokenMc = "";
          let tokenChart = "";
          let price5min = "";
          let price1hr = "";
          let price6hr = "";
          let price24hr = "";
          let color5min = "";
          let color1hr = "";
          let color6hr = "";
          let color24hr = "";
          let showFundButton = "";

          if (funderAddress !== "0x0000000000000000000000000000000000000000" && contractAddress === "0x0000000000000000000000000000000000000000") {
            tokenName = "Ready To Fund";
            tokenColor = "#ffa63e"; // Change color based on condition
            showFundButton ="true"
          }

          if (contractAddress !== "0x0000000000000000000000000000000000000000" && funderAddress !== "0x0000000000000000000000000000000000000000") {
            const TempContract = new Contract(contractAddress, token.abi, signer);
            const newName = await TempContract.name();

            tokenName = newName;
            const tokenInfo = await fetchTokenData(contractAddress); // Fetch the token data
            tokenLogo = tokenInfo.tokenLogo;
            tokenTelegram = tokenInfo.tokenTelegram;
            tokenTwitter = tokenInfo.tokenTwitter;
            tokenWebsite = tokenInfo.tokenWebsite;
            tokenMc = tokenInfo.marketCap;
            tokenColor = "#8DE4FF";
            tokenChart = tokenInfo.tokenChart;
            price5min = tokenInfo.price5min;
            price1hr = tokenInfo.price1hr;
            price6hr = tokenInfo.price6hr;
            price24hr = tokenInfo.price24hr;
            color5min = tokenInfo.color5min;
            color1hr = tokenInfo.color1hr;
            color6hr = tokenInfo.color6hr;
            color24hr = tokenInfo.color24hr;
             showFundButton = "false"
          }

          return {
            tokenId: "Dealix ID # " + tokenId.toString(),
            baseToken: tokenId,
            owner: voteData[0],
            funder: voteData[1],
            name: voteData[2],
            contractAddress: voteData[3],
            bonusPercentage: voteData[4],
            expirationTime: voteData[5],
            startingRewards: voteData[6],
            tknVestTime: voteData[7],
            rewardsSent: voteData[8],
            volume: voteData[9],
            ethRewards: voteData[10],
            tokenName: tokenName,
            tokenLogo: tokenLogo,
            tokenTG: tokenTelegram,
            tokenTW: tokenTwitter,
            tokenWS: tokenWebsite,
            tokenMC: tokenMc,
            tokenColor: tokenColor,
            chart: tokenChart,
            price5min: price5min,
            price1hr: price1hr,
            price6hr: price6hr,
            price24hr: price24hr,
            color5min: color5min,
            color1hr: color1hr,
            color6hr: color6hr,
            color24hr: color24hr,
            showFundButton: showFundButton

          };
        });

        const votesData = await Promise.all(activeRemoveVotesPromises);
        setFundersTokensId(votesData); // Ensure `newestTokensId` state is updated with complete data
      }
    } catch (err) {
      console.log(err.message);
    }
  } else {
    setFundersTokensId(["No Deals Found..."]);
  }
}


























const fetchDiscountInfo = async (Boob) => {
  if (address) {
    console.log("Making The Call");
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);

    try {
      const coupon = await discountContract.discounts(Boob);
      const Couponname = coupon[2].toString();
      const Volume = `${formatUnits(coupon[9], 18)} ETH`;

      const Rewardspercent = coupon[4].toString();
      const expiresUnix = parseInt(coupon[5].toString(), 10); // Convert to integer
      const expiresDate = new Date(expiresUnix * 1000); // Convert Unix timestamp to JavaScript Date object

      // Define the cutoff date
      const cutoffDate = new Date("2050-01-01T00:00:00Z");

      // Check if the expiresDate is later than January 1, 2050
      const expires = expiresDate > cutoffDate ? "Please Close Dealix" : expiresDate.toLocaleString();

      const remining = coupon[8].toString();
      const isEth = coupon[10].toString();
      const startReward = coupon[6].toString();
      const contract = coupon[3].toString();

      const tokenContract = new Contract(contract, token.abi, signer);
      const tokenDecimals = await tokenContract.decimals();
      
      console.log(Couponname);
      console.log(Volume);
      console.log(Rewardspercent);
      console.log(expires);

      let final, left, total;

      if (isEth === "true") {
        final = bigInt(startReward).minus(bigInt(remining)).toString(); // Use bigInt for subtraction
        left = parseFloat(formatEther(final)).toFixed(3);
        total = `${left} ETH`;
      } else {
        final = bigInt(startReward).minus(bigInt(remining)); // Use bigInt for subtraction
        left = parseFloat(formatUnits(final.toString(), tokenDecimals)).toFixed(3);
        total = `${left}`;
      }
      console.log(total + "Total");

      setName("Dealix Name: " + Couponname);
      setVolume("Dealix Volume: " + Volume);
      setRewardPercent("Rewards Percentage: " + Rewardspercent + "%");
      setDiscountExpiry("Closing Date: " + expires);
      setRemaining("Remaining Rewards: " + total);

    } catch (err) {
      console.error(err.message);
    }
  }
};




async function buyWithDiscount(id) {
  try {
    // Convert AddBuyETH to a correctly formatted string

  
    const eth = AddBuyETH;
    const slip = AddBuySlip.toString();
    const showing = formatEther(parseEther(eth)) + " ETH";

    // Convert ETH value to wei
    const wei = parseEther(eth);
    console.log(eth)
    console.log(id);
    console.log(wei.toString()); // Log wei value as string
    console.log(slip);

    const ethersProvider = new BrowserProvider(walletProvider); // Adjust walletProvider as needed
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(DiscountContractAddress, discount.abi, signer);

    // Initiate the transaction
    const transaction = await contract.buyWithDiscountv2(id, wei, slip, { value: wei });

    // Show pending transaction alert
    Swal.fire({
      icon: 'info',
      title: 'Transaction Pending',
      text: `Purchasing Dealix #${id} for ${showing}. Please wait...`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });

    // Wait for the transaction to be confirmed
    const receipt = await transaction.wait();
    console.log('Transaction successful:', receipt);

    // Close the pending alert and show success alert
    Swal.close();
    Swal.fire({
      icon: 'success',
      title: 'Transaction Successful',
      text: `Purchased Dealix ${id} for ${showing}.`,
    });
  } catch (error) {
    console.error('Transaction failed:', error);

    // Show error alert
    Swal.fire({
      icon: 'error',
      title: 'Transaction Failed',
      text: error.reason || error.message,
    });
  }
}



 



  






 








  return (

    <Box mb="100px">
      <Box>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="1px">
          <Flex justify="center" align="center" mb="10px" py="25px" position="contain" top="0" zIndex="contain">
            {isConnected ? (<w3m-account-button balance="show" />)
              :
              (<w3m-connect-button size="sm" label="Connect Wallet" onClick={connectWallet} />)}
            <w3m-network-button />
          </Flex>



          <Card className='Btns' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold">
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px">
              <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">

                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">

                </Grid>


                <br></br>

                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="1px">



                    <Card
                      className='NFTHead'
                      textAlign="center"
                      borderRadius="30px"
                      fontSize="30px"
                      fontWeight="bold"
                    >
                      <Box className='cardtxt' marginBottom="10px">
                        <Text className='FullHeading'>Welcome To Dealix</Text>
                        <Text className='SHeading'>Discover unbeatable crypto deals, simplified.
                        </Text>
                       
                      </Box>
                      <br></br>




                      <Box className='info-container'>
                        <Box className='cardtxt' marginBottom="10px">
                          <Text className='Heading' >Total Active Deals</Text>
                           
                          <Text>{showTotalDiscountsActive}</Text>
                        </Box>
                        <Box className='cardtxt' marginBottom="10px">
                          <Text className='Heading'>Total ETH Generated</Text>
                          <Text>{showTotalEth}</Text>
                        </Box>
                      </Box>
                      <br></br>
                      <div className="toggle-container">
                      <button className={`toggle-button ${infoValue === 'info' ? 'active' : ''}`} onClick={() => handleInfo('info')}>
                        Information

                      </button>
                      <button className={`toggle-button ${infoValue === 'search' ? 'active' : ''}`} onClick={() => handleInfo('search')}>
                        Search
                      </button>
                      <button className={`toggle-button ${infoValue === 'buy' ? 'active' : ''}`} onClick={() => handleInfo('buy')}>
                        Buy & Claim

                      </button>
                      
                      <button className={`toggle-button ${infoValue === 'setup' ? 'active' : ''}`} onClick={() => handleInfo('setup')}>
                        Setup & Start
                      </button>
                      <button className={`toggle-button ${infoValue === 'affiliate' ? 'active' : ''}`} onClick={() => handleInfo('affiliate')}>
                        Affiliate 

                      </button>

                      </div>
                      <br></br>

                    </Card>
                  


              
                </Grid>
            
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="1px">
                <Leaderboard />
            
                <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">










{showInfo && (
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="1px">
                 <Infomation/>
                </Grid>



  )}




{showAffilitate && (
  <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px">

<Box className='cardtxt' marginBottom="10px">
        <Text className='FullHeading'>Your Stats</Text>
      </Box>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="20px">
  
    <Box className='cardtxt' marginBottom="10px" flexBasis={{ base: '100%', md: '25%' }}>
      <Text className='Heading'>Total ETH Made</Text>
      <Text>{ethMade}</Text>
    </Box>
    <Box className='cardtxt' marginBottom="10px" flexBasis={{ base: '100%', md: '25%' }}>
      <Text className='Heading'>Total Volume Generated</Text>
      <Text>{volume}</Text>
    </Box>

</Grid>
    
    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
      <Box className='cardtxt' marginBottom="10px">
        <Text className='FullHeading'>Your Dealix Cards</Text>
      </Box>
      <Box className='cardtxt' marginBottom="10px">
      <Button 
        className='btnsubmit1' 
        border='1px' 
        borderRadius={30} 
        variant='transparent' 
        marginInline={2}  
        fontSize="15px" 
        colorScheme='blue' 
        color='#E9D8FD' 
        onClick={Mintdiscount}
      >
        <b>Mint New Dealix Card</b>
      </Button>
      </Box>

      <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">

    </Card>
      
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap="10px">
  {userDiscountsData.length === 0 ? (
      <Card className="vote-card-claim" gridColumn="span 5">
      <Box className='cardtxt' marginBottom="10px" width="100%">
        <Text className='Heading'  textAlign="center">
        No Dealix Cards Found, Please Mint To Begin
        </Text>
      </Box>
    </Card>
  ) : (
    userDiscountsData.map((vote, index) => (
      <div key={index}>
        {!address ? (
          <div className="toggle-container"></div>
        ) : (
          <Card key={index} className="vote-card-newclaim">
            <Box className="card-header10">
              <Text> {vote.tokenId}</Text>
              <Text color={vote.tokenColor}>{vote.tokenName}</Text>
              <Box className='cardtxt' marginBottom="10px">
                {vote.tokenLogo ? (
                  <>
                    <img className='bnbpool' src={vote.tokenLogo} alt="Token Logo" style={{ width: '50%', height: '50%', objectFit: 'contain' }} /> 
                    <Box className="card-header10" marginTop={15}>
                    <Text className='SMHeading'> MarketCap</Text>
                    <Text className='SMHeading'>{vote.tokenMC}</Text>
                  </Box>
                  </>
                ) : (
                  <>
                  <img className='bnbpool' src={BaseLogo} alt="Token Logo" style={{objectFit: 'contain' }} />
                
                  <Box className="card-header10" marginTop={15}>
                    <Text className='SMHeading'> MarketCap</Text>
                    <Text className='SMHeading'>Not Available</Text>
                  </Box>
                    </>
                )}




<Box className="card-header10" marginTop="15px">
        <Grid templateColumns="repeat(4, 1fr)" gap={0} justifyContent="center" alignItems="center">
          {vote.price5min !== undefined && (
            <Box textAlign="center">
            <Text className='SLHeading'>5Min</Text>
              <Text className='SLHeading'style={{ color: vote.color5min }}>{vote.price5min}%</Text>
            </Box>
          )}
          {vote.price1hr !== undefined && (
            <Box textAlign="center">
              <Text className='SLHeading' >1h</Text>
              <Text className='SLHeading'style={{ color: vote.color1hr }}>{vote.price1hr}%</Text>
            </Box>
          )}
          {vote.price6hr !== undefined && (
            <Box textAlign="center">
              <Text className='SLHeading'>6h</Text>
              <Text className='SLHeading'style={{ color: vote.color6hr }}>{vote.price6hr}%</Text>
            </Box>
          )}
          {vote.price24hr !== undefined && (
            <Box textAlign="center">
              <Text className='SLHeading'>24h</Text>
              <Text className='SLHeading'style={{ color: vote.color24hr }}>{vote.price24hr}%</Text>
            </Box>
          )}
        </Grid>
</Box>


{vote.isFunded === "true" ? (
  <Box className="token-info-card" marginTop="15px">
    <Flex justifyContent="center" alignItems="center">
      {vote.tokenTG && (
        <a href={vote.tokenTG} target="_blank" rel="noopener noreferrer">
          <Image src={Telegram} alt="Telegram" boxSize="25px" mr={2} />
        </a>
      )}
      {vote.tokenWS && (
        <a href={vote.tokenWS} target="_blank" rel="noopener noreferrer">
          <Image src={Website} alt="Website" boxSize="30px" mr={2} />
        </a>
      )}
      {vote.tokenTW && (
        <a href={vote.tokenTW} target="_blank" rel="noopener noreferrer">
          <Image src={Twitter} alt="Twitter" boxSize="25px" mr={2} />
        </a>
      )}
      {vote.chart && (
        <a href={vote.chart} target="_blank" rel="noopener noreferrer">
          <Image src={Chart} alt="Chart" boxSize="25px" />
        </a>
      )}
    </Flex>
  </Box>
) : (
  <Box className="token-info-card" marginTop="15px">
    <Flex justifyContent="center" alignItems="center">
      <Box className="card-header10" marginTop="0px">
        <Text className='SMHeading'>No Socials Found</Text>
      </Box>
    </Flex>
  </Box>
)}



               
              </Box>
              {vote.canInteract === true ? (
  <>
    <Box className="card-content10"></Box>
    <Button
      className="btnsubmit1"
      border="1px"
      borderRadius={30}
      variant="transparent"
      marginInline={2}
      fontSize="15px"
      colorScheme="blue"
      color="#E9D8FD"
      onClick={() => setSelfFunderdiscount(vote.baseToken)}
    >
      <b>Self Fund</b>
    </Button>
    <Popup
      className="popupimage"
      trigger={
        <Button
          className="btnsubmit1"
          border="1px"
          borderRadius={30}
          variant="transparent"
          marginInline={2}
          fontSize="15px"
          colorScheme="blue"
          color="#E9D8FD"
          onClick={() => {}}
        >
          <b>Manage</b>
        </Button>
      }
      modal
      nested
    >
      {close => (
        <div className="modal">
          <div className="headerMD">
            <Box className="cardtxt" marginBottom="10px">
              <Text className="SHeading">Managing {vote.tokenId}</Text>
            </Box>
            <Text className="Heading">Set User To Start</Text>
            <div className="chatenai-separator"></div>
          </div>
          <Flex justify="center">
            <Box className="cardtxt" marginBottom="10px">
              <Text className="SHeading">Enter Users Address</Text>
            </Box>
            <Box className="cardtxt1" marginBottom="10px" width="85%">
              <input
                id="eth-amount"
                type="text"
                className="form-control"
                onChange={handleDepositFunderAddress}
                value={funderDepositAddress}
                placeholder="0x000..."
              />
            </Box>
            <Box className="cardtxt" marginBottom="10px">
              <Text className="SSHeading">Example</Text>
              <Text className="SSHeading">0x000000000000000000000000000000000000dEaD</Text>
            </Box>
            <Box className="cardtxt" marginBottom="10px">
              <Button
                className="btnsubmit1"
                border="1px"
                borderRadius={30}
                variant="transparent"
                marginInline={2}
                fontSize="15px"
                colorScheme="blue"
                color="#E9D8FD"
                onClick={() => setFunderdiscount(vote.baseToken)}
              >
                <b>Set Users Address</b>
              </Button>
            </Box>
            <Stack order={{ base: "2", md: "1" }} spacing="5px">
              <Button
                className="btnsubmit1"
                border="1px"
                borderRadius={30}
                variant="transparent"
                marginInline={2}
                fontSize="15px"
                colorScheme="blue"
                color="#E9D8FD"
                onClick={() => {
                  // Reset state variables

                  // Close the modal
                  close();
                }}
              >
                <b>Close Window</b>
              </Button>
            </Stack>
          </Flex>
        </div>
      )}
    </Popup>
  </>
) : null}

{vote.canRevoke === true ? (
  <>
   <Box className="card-content10"></Box>
   <Button
     className="btnsubmit1"
     border="1px"
     borderRadius={30}
     variant="transparent"
     marginInline={2}
     fontSize="15px"
     colorScheme="blue"
     color="#E9D8FD"
     onClick={() => setRevoke(vote.baseToken)}
   >
     <b>Revoke</b>
   </Button>
   </>
) : null}

            
            </Box>
          </Card>
        )}
      </div>
    ))
  )}
</Grid>

    </Card>

  </Grid>
)}


{showSearch && (
  <div>
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
      <Card></Card>
      <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
        <Box className='cardtxt' marginBottom="10px">
          <Text className='FullHeading'>Search Deals</Text>
          <Box>
            <div>
              <Box>
                <Text className='Heading'>Enter Contract Address</Text>
                <input
                  id="project-ticker"
                  type="text"
                  className="form-control"
                  onChange={handleDepositSearchContract}
                  value={depositValueSearchContract}
                  placeholder="0x123..."
                />
              </Box>
        {showSearchData && searchData &&(
              <Box className='cardtxt' marginBottom="10px">
                <br></br>
                <Text className='Heading'>Token Name & Symbol</Text>
              <Text >{showSearchName}</Text>
              <Text >${showSearchSym}</Text>
               </Box>
        )}

              {invalidSearchError && <p>Invalid contract address.</p>}
            </div>
          </Box>
        </Box>
      </Card>
    </Grid>
    {showSearchData && searchData && searchData.length == 0 && (

<Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
<Card></Card>
<Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
  <Box className='cardtxt' marginBottom="10px">
    <Text className='FullHeading'>No Active Deals</Text>
   
  </Box>
</Card>
</Grid>



    )}

    {showSearchData && searchData && searchData.length > 0 && (
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap="20px">
        {searchData.map((data, index) => (
          <Card key={index} className="vote-card-remove">
           
            <Box className="card-header10">
              <Text> {data.tokenId}</Text>
              <Text color={data.tokenColor}>{data.tokenName}</Text>
            </Box>
            <Box className="card-header10" marginTop="15px">
              <Grid templateColumns="repeat(3, 1fr)" gap={2} justifyContent="center" alignItems="center">
                <Box textAlign="center">
                  <Text className='SLHeading'>RT</Text>
                  <Text className='SMDHeading'>{data.tknVestType}</Text>
                </Box>
                <Box textAlign="center">
                  <Text className='SLHeading'>RP</Text>
                  <Text className='SMDHeading'>{data.bonusPercentage}</Text>
                </Box>
                <Box textAlign="center">
                  <Text className='SLHeading'>VT</Text>
                  <Text className='SMDHeading'>{data.tknVestTime}</Text>
                </Box>
              </Grid>
              <Box className="card-header10" marginTop={15}>
                <Text className='SLHeading'> Dealix Volume</Text>
                <Text className='SMDHeading'>{data.volume}</Text>
              </Box>
            </Box>
            <Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {data.buyTax !== undefined && data.buyTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: data.buyTaxColor }}>{data.buyTax}%</Text>
      </Box>
    )}
    {(data.buyTax === undefined || data.buyTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: data.buyTaxColor }}>{data.buyTax}</Text>
      </Box>
    )}
    {data.sellTax !== undefined && data.sellTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: data.sellTaxColor }}>{data.sellTax}%</Text>
      </Box>
    )}
    {(data.sellTax === undefined || data.sellTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: data.sellTaxColor }}>{data.sellTax}</Text>
      </Box>
    )}
  </Grid>
</Box>

<Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {data.isContractRenounced !== undefined && data.isContractRenounced !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: data.renouncedColor }}>{data.isContractRenounced}</Text>
      </Box>
    )}
    {(data.isContractRenounced === undefined || data.isContractRenounced === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: data.renouncedColor }}>{data.isContractRenounced}</Text>
      </Box>
    )}
    {data.isHoneypot !== undefined && data.isHoneypot !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: data.isHoneypotColor }}>{data.isHoneypot}</Text>
      </Box>
    )}
    {(data.isHoneypot === undefined || data.isHoneypot === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: data.isHoneypotColor }}>{data.isHoneypot}</Text>
      </Box>
    )}
  </Grid>
</Box>
            <Box className='cardtxt' marginBottom="10px">
              <Box className="card-header10" marginTop="15px">
                <Grid templateColumns="repeat(4, 1fr)" gap={0} justifyContent="center" alignItems="center">
                  {data.price5min !== undefined && (
                    <Box textAlign="center">
                      <Text className='SLHeading'>5Min</Text>
                      <Text className='SLHeading' style={{ color: data.color5min }}>{data.price5min}%</Text>
                    </Box>
                  )}
                  {data.price1hr !== undefined && (
                    <Box textAlign="center">
                      <Text className='SLHeading'>1h</Text>
                      <Text className='SLHeading' style={{ color: data.color1hr }}>{data.price1hr}%</Text>
                    </Box>
                  )}
                  {data.price6hr !== undefined && (
                    <Box textAlign="center">
                      <Text className='SLHeading'>6h</Text>
                      <Text className='SLHeading' style={{ color: data.color6hr }}>{data.price6hr}%</Text>
                    </Box>
                  )}
                  {data.price24hr !== undefined && (
                    <Box textAlign="center">
                      <Text className='SLHeading'>24h</Text>
                      <Text className='SLHeading' style={{ color: data.color24hr }}>{data.price24hr}%</Text>
                    </Box>
                  )}
                </Grid>
              </Box>
              <Popup
                    className='popupimage'
                    trigger={
                      <Button
                        className='btnsubmit1'
                        border='1px'
                        borderRadius={30}
                        variant='transparent'
                        marginInline={2}
                        fontSize="15px"
                        colorScheme='blue'
                        color='#E9D8FD'
                        onClick={() => {}}
                      >
                        <b>Buy This Deal</b>
                      </Button>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <div className='headerMD'>
                        <Box className='cardtxt' marginBottom="10px">
                        <Text className='SHeading'>Buying {data.baseId}</Text>
                      </Box>
                      <Box className='cardtxt' marginBottom="10px">
          
            </Box>
            <div>
             
              <Box className='cardtxt' marginBottom="25px"></Box>

         

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter ETH Amount</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="eth-amount"
                  type="text"
                  className="form-control"
                  onChange={handleDepositBuyETH}
                  value={AddBuyETH}
                  placeholder="0.25 ETH"
                />
              </div>
              <Box className='cardtxt' marginBottom="25px"></Box>

          

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter Slippage</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="slippage"
                  type="text"
                  className="form-control"
                  onChange={handleDepositBuySlip}
                  value={AddBuySlip}
                  placeholder="5%"
                />
              </div>
              <Button 
                  className='btnsubmit1' 
                  border='1px' 
                  marginTop="15px"
                  borderRadius={30} 
                  variant='transparent' 
                  marginInline={2}  
                  fontSize="15px" 
                  colorScheme='blue' 
                  color='#E9D8FD' 
                  onClick={() => buyWithDiscount(data.baseToken)}
                >
                  <b>Buy {data.tokenName}</b>
                </Button>
                <br></br>
         
            </div>
                       
                          <div className="chatenai-separator"></div>
                        </div>


                        <Flex justify="center">
                          <Stack order={{ base: '2', md: '1' }} spacing="5px">
                          <Button
                            className='btnsubmit1'
                            border='1px'
                            borderRadius={30}
                            variant='transparent'
                            marginInline={2}
                            fontSize="15px"
                            colorScheme='blue'
                            color='#E9D8FD'
                            onClick={() => {
                              // Reset state variables

                              // Close the modal
                              close();
                              handleCloseBuy(); // Corrected function call
                            }}
                          >
                            <b>Close Window</b>
                          </Button>
                          </Stack>
                        </Flex>
                      </div>
                    )}
                  </Popup>
            </Box>
          </Card>
        ))}
      </Grid>
    )}
  </div>
)}



{showBuy && (
    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap="20px">
         
          <Box> 
    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
      <Box className='cardtxt' marginBottom="10px">
        <Text className='FullHeading'>Purchase Dealix</Text>
      </Box>
      {showBuyinput && (
   <Box>
   <input
     id="project-ticker"
     type="text"
     className="form-control"
     onChange={handleDepositBuyContract}
     value={depositValueBuyContract}
     placeholder="Enter Dealix ID"
   />
   <br></br>

   <Button
     className="btnsubmit1"
     border="1px"
     borderRadius={30}
     variant="transparent"
     marginInline={2}
     fontSize="15px"
     colorScheme="blue"
     color="#E9D8FD"
     onClick={searchDepositBuy}
   >
     <b>Search</b>
   </Button>
 </Box>
)}



      {invalidIdError  && (
        <Box className='cardtxt' marginTop="20px">
          <Text className='FullHeading' color="red">Invalid Dealix ID</Text>
          <Text className='FullHeading'>Please enter a valid Dealix ID.</Text>
        </Box>
      )}

  {loadingData && !buyData && (
        <Box className='cardtxt' marginTop="20px">
          <Text className='FullHeading' color="red">Please Wait</Text>
          <Text className='FullHeading'>Fetching Data.</Text>
        </Box>
      )}

      {showBuyData && buyData && !invalidIdError && (
        <>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="20px">
          <Card  className="vote-card-remove">
          <Box className="card-header10">
            <Text>{buyData.tokenId}</Text>
            <Text color={buyData.tokenColor}>{buyData.tokenName}</Text>
          </Box>
          <Box className="card-header10" marginTop="15px">
            <Grid templateColumns="repeat(3, 1fr)" gap={2} justifyContent="center" alignItems="center">
              <Box textAlign="center">
                <Text className='SLHeading'>RT</Text>
                <Text className='SMDHeading'>{buyData.ethRewards}</Text>
              </Box>
              <Box textAlign="center">
                <Text className='SLHeading'>RP</Text>
                <Text className='SMDHeading'>{buyData.bonusPercentage}</Text>
              </Box>
              <Box textAlign="center">
                <Text className='SLHeading'>VT</Text>
                <Text className='SMDHeading'>{buyData.tknVestTime}</Text>
              </Box>
            </Grid>
            <Box className="card-header10" marginTop={15}>
              <Text className='SLHeading'>Dealix Volume</Text>
              <Text className='SMDHeading'>{buyData.volume}</Text>
            </Box>
          </Box>
          <Box className='cardtxt' marginBottom="10px">
            {buyData.tokenLogo ? (
              <img
                className='bnbpool'
                src={buyData.tokenLogo}
                alt="Token Logo"
                style={{ width: '50%', height: '50%', objectFit: 'contain' }}
              />
            ) : (
              <img
                className='bnbpool'
                src={BaseLogo}
                alt="Token Logo"
                style={{ objectFit: 'contain' }}
              />
            )}
            <Box className="card-header10" marginTop={15}>
              <Text className='SMHeading'>MarketCap</Text>
              <Text className='SMHeading'>{buyData.tokenMC}</Text>
            </Box>
            <Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {buyData.buyTax !== undefined && buyData.buyTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: buyData.buyTaxColor }}>{buyData.buyTax}%</Text>
      </Box>
    )}
    {(buyData.buyTax === undefined || buyData.buyTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: buyData.buyTaxColor }}>{buyData.buyTax}</Text>
      </Box>
    )}
    {buyData.sellTax !== undefined && buyData.sellTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: buyData.sellTaxColor }}>{buyData.sellTax}%</Text>
      </Box>
    )}
    {(buyData.sellTax === undefined || buyData.sellTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: buyData.sellTaxColor }}>{buyData.sellTax}</Text>
      </Box>
    )}
  </Grid>
</Box>

<Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {buyData.isContractRenounced !== undefined && buyData.isContractRenounced !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: buyData.renouncedColor }}>{buyData.isContractRenounced}</Text>
      </Box>
    )}
    {(buyData.isContractRenounced === undefined || buyData.isContractRenounced === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: buyData.renouncedColor }}>{buyData.isContractRenounced}</Text>
      </Box>
    )}
    {buyData.isHoneypot !== undefined && buyData.isHoneypot !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: buyData.isHoneypotColor }}>{buyData.isHoneypot}</Text>
      </Box>
    )}
    {(buyData.isHoneypot === undefined || buyData.isHoneypot === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: buyData.isHoneypotColor }}>{buyData.isHoneypot}</Text>
      </Box>
    )}
  </Grid>
</Box>
            <Box className="card-header10" marginTop="15px">
              <Grid templateColumns="repeat(4, 1fr)" gap={0} justifyContent="center" alignItems="center">
                {buyData.price5min !== undefined && (
                  <Box textAlign="center">
                    <Text className='SLHeading'>5Min</Text>
                    <Text className='SLHeading' style={{ color: buyData.color5min }}>{buyData.price5min}%</Text>
                  </Box>
                )}
                {buyData.price1hr !== undefined && (
                  <Box textAlign="center">
                    <Text className='SLHeading'>1h</Text>
                    <Text className='SLHeading' style={{ color: buyData.color1hr }}>{buyData.price1hr}%</Text>
                  </Box>
                )}
                {buyData.price6hr !== undefined && (
                  <Box textAlign="center">
                    <Text className='SLHeading'>6h</Text>
                    <Text className='SLHeading' style={{ color: buyData.color6hr }}>{buyData.price6hr}%</Text>
                  </Box>
                )}
                {buyData.price24hr !== undefined && (
                  <Box textAlign="center">
                    <Text className='SLHeading'>24h</Text>
                    <Text className='SLHeading' style={{ color: buyData.color24hr }}>{buyData.price24hr}%</Text>
                  </Box>
                )}
              </Grid>
            </Box>
            <Box className="token-info-card" marginTop="15px">
                            <Flex justifyContent="center" alignItems="center">
                              {buyData.tokenTG && (
                                <a href={buyData.tokenTG} target="_blank" rel="noopener noreferrer">
                                  <Image src={Telegram} alt="Telegram" boxSize="25px" mr={2} />
                                </a>
                              )}
                              {buyData.tokenWS && (
                                <a href={buyData.tokenWS} target="_blank" rel="noopener noreferrer">
                                  <Image src={Website} alt="Website" boxSize="30px" mr={2} />
                                </a>
                              )}
                              {buyData.tokenTW && (
                                <a href={buyData.tokenTW} target="_blank" rel="noopener noreferrer">
                                  <Image src={Twitter} alt="Twitter" boxSize="25px" mr={2} />
                                </a>
                              )}
                              {buyData.chart && (
                                <a href={buyData.chart} target="_blank" rel="noopener noreferrer">
                                  <Image src={Chart} alt="Chart" boxSize="25px" />
                                </a>
                              )}
                            </Flex>
                          </Box>










                          <Box className="card-content10"></Box>
                 
           
          </Box>
          <br></br>
          <Popup
                    className='popupimage'
                    trigger={
                      <Button
                        className='btnsubmit1'
                        border='1px'
                        borderRadius={30}
                        variant='transparent'
                        marginInline={2}
                        fontSize="15px"
                        colorScheme='blue'
                        color='#E9D8FD'
                        onClick={() => {}}
                      >
                        <b>Buy {buyData.tokenName}</b>
                      </Button>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <div className='headerMD'>
                        <Box className='cardtxt' marginBottom="10px">
                        <Text className='SHeading'>Buying {buyData.baseId}</Text>
                      </Box>
                      <Box className='cardtxt' marginBottom="10px">
          
            </Box>
            <div>
             
              <Box className='cardtxt' marginBottom="25px"></Box>

         

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter ETH Amount</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="eth-amount"
                  type="text"
                  className="form-control"
                  onChange={handleDepositBuyETH}
                  value={AddBuyETH}
                  placeholder="0.25 ETH"
                />
              </div>
              <Box className='cardtxt' marginBottom="25px"></Box>

          

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter Slippage</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="slippage"
                  type="text"
                  className="form-control"
                  onChange={handleDepositBuySlip}
                  value={AddBuySlip}
                  placeholder="5%"
                />
              </div>
              <Button 
                  className='btnsubmit1' 
                  border='1px' 
                  marginTop="15px"
                  borderRadius={30} 
                  variant='transparent' 
                  marginInline={2}  
                  fontSize="15px" 
                  colorScheme='blue' 
                  color='#E9D8FD' 
                  onClick={() => buyWithDiscount(buyData.baseToken)}
                >
                  <b>Buy {buyData.tokenName}</b>
                </Button>
                <br></br>
         
            </div>
                       
                          <div className="chatenai-separator"></div>
                        </div>


                        <Flex justify="center">
                          <Stack order={{ base: '2', md: '1' }} spacing="5px">
                          <Button
                            className='btnsubmit1'
                            border='1px'
                            borderRadius={30}
                            variant='transparent'
                            marginInline={2}
                            fontSize="15px"
                            colorScheme='blue'
                            color='#E9D8FD'
                            onClick={() => {
                              // Reset state variables

                              // Close the modal
                              close();
                              handleCloseBuy(); // Corrected function call
                            }}
                          >
                            <b>Close Window</b>
                          </Button>
                          </Stack>
                        </Flex>
                      </div>
                    )}
                  </Popup>
<br></br>



        
          <Flex justify="center">
                          <Stack order={{ base: '2', md: '1' }} spacing="5px">
                          <Box className='cardtxt' marginBottom="10px">
                          <Button
                            className='btnsubmit1'
                            border='1px'
                            borderRadius={30}
                            variant='transparent'
                            marginInline={2}
                            fontSize="15px"
                            colorScheme='blue'
                            color='#E9D8FD'
                            onClick={() => {
                              // Reset state variables

                              // Close the modal
                            
                              handleCloseBuy(); // Corrected function call
                            }}
                          >
                            <b>Go Back</b>
                          </Button>
                          </Box>
                          </Stack>
                        </Flex>

          </Card>
       
          </Grid>
        </>
      )}
      
    </Card>
    </Box>


    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="9px">
    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
  <Box  marginBottom="10px">
    <Text className='FullHeading'>Claim Vesting</Text>
  </Box>
  <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="10px">
    {unclaimedDiscounts.length === 0 ? (
      
      <Card className="vote-card-claim" gridColumn="span 2">
      <Box  marginBottom="10px" width="100%">
        <Text className='Heading'  textAlign="center">
          No Unclaimed Deals Found
        </Text>
      </Box>
    </Card>
    ) : (
      <>
        {unclaimedDiscounts.map((discount, index) => (
          <Card key={index} className="vote-card-newclaim">
            <Box className="card-header10">
              <h4>Dealix ID: {discount.tokenId}</h4>
              <hr />
              <Text> {discount.tokenName}</Text>
              <Text marginTop={4}>Rewards Amount:</Text>
              <Text className='SMHeading'>{discount.vestingAmount}</Text>
              <Text marginTop={4}>Unlock Time:</Text>
              <Text className='SMHeading'> {discount.unlockTime}</Text>
              <Text marginTop={4}>Holdings:</Text>
              <Text className='SMHeading'>{discount.holdingsAmount}</Text>
              <hr marginBottom={5} />
              {discount.canClaim && (
              

<Button
className='btnsubmit1'
border='1px'
borderRadius={30}
variant='transparent'
marginInline={2}
fontSize="15px"
colorScheme='blue'
color='#E9D8FD'
onClick={() => {
  // Reset state variables

  // Close the modal

  handleClaimType(discount.claimType, discount.tokenId); // Corrected function call
}}
>
<b>Claim {discount.tokenName}</b>
</Button>
              )}
              
            </Box>
          </Card>
        ))}
     </>
    )}
  </Grid>
</Card>







    </Grid>
    </Grid>
)}







{showSetup && (
  <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap="20px">
      <Box>
    
    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
      <Box className='cardtxt' marginBottom="10px">
        <Text className='FullHeading'>Your Ready To Fund Dealix Cards</Text>
      </Box>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="10px">
        
        {fundersTokensId.length === 0 ? (
           <Card className="vote-card-claim" gridColumn="span 2">
           <Box className='cardtxt' marginBottom="10px" width="100%">
             <Text className='Heading'  textAlign="center">
              No Deals To Fund
             </Text>
           </Box>
         </Card>
        ) : (
          fundersTokensId.map((vote, index) => (
            <div key={index}>
              {!address ? (
                <div className="toggle-container"></div>
              ) : (
                <Card key={index} className="vote-card-remove">
                  <Box className="card-header10">
                  <Text> {vote.tokenId}</Text> 
                  <Text color={vote.tokenColor}>{vote.tokenName}</Text>
                  <Box className='cardtxt' marginBottom="10px">
                  {vote.tokenLogo ? (
                      <>
                      <img className='bnbpool' src={vote.tokenLogo} alt="Token Logo" style={{ width: '50%', height: "auto", objectFit: 'contain' }} />
                     
                      <Box className="card-header10">
                      <Text className='SMHeading'> MarketCap</Text> 
                      <Text className='SMHeading'>{vote.tokenMC}</Text>
                      </Box>
                      </>
                  ) : (
                    <img className='bnbpool' src={BaseLogo} alt="Token Logo" style={{ objectFit: 'contain' }} />
                  )}
                

                  <Box className="token-info-card" marginTop="15px">
                        <Flex justifyContent="center" alignItems="center">
                        {vote.tokenTG && (
                            <a href={vote.tokenTG} target="_blank" rel="noopener noreferrer">
                              <Image src={Telegram} alt="Telegram" boxSize="30px" mr={2} />
                            </a>
                          )}

                          {vote.tokenWS && (
                            <a href={vote.tokenWS} target="_blank" rel="noopener noreferrer">
                              <Image src={Website} alt="Website" boxSize="30px" mr={2} />
                            </a>
                          )}
                         
                          {vote.tokenTW && (
                            <a href={vote.tokenTW} target="_blank" rel="noopener noreferrer">
                              <Image src={Twitter} alt="Twitter" boxSize="30px" />
                            </a>
                          )}
                        </Flex>
                      </Box>
                      </Box>
                </Box>
                  <Box className="card-content10"></Box>
                  
                  <Popup
                    className='popupimage'
                    trigger={
                      <Button
                        className='btnsubmit1'
                        border='1px'
                        borderRadius={30}
                        variant='transparent'
                        marginInline={2}
                        fontSize="15px"
                        colorScheme='blue'
                        color='#E9D8FD'
                        onClick={() => {}}
                      >
                        <b>Manage</b>
                      </Button>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <div className='headerMD'>
                        <Box className='cardtxt' marginBottom="10px">
                        <Text className='SHeading'>Managing {vote.tokenId}</Text>
                      </Box>
                        <Text className='Heading' marginBottom="15px">Select Option</Text>
                        <div className="toggle-container">
                        {vote.showFundButton == "true" && (
                      <button className={`toggle-button ${funderManageValue === 'fund' ? 'active' : ''}`} onClick={() => handleFunderManage('fund')}>
                        Fund Dealix

                      </button>
                      ) }



                      
                      <button
                        className={`toggle-button ${funderManageValue === 'manage' ? 'active' : ''}`}
                        onClick={() => handleFunderManagecall(vote.baseToken)}
                      >
                        Manage Dealix
                      </button>
                       </div>
                          <div className="chatenai-separator"></div>
                        </div>
  {funderManageFund && (
     <Box className='cardtxt' marginBottom="10px">
      



  {funderManageCaFund && (
    <div>
          <label htmlFor="project-ticker">Enter Contract Address</label>
          <br></br>
              <input
                id="project-ticker"
                type="text"
                className="form-control"
                onChange={handleDepositFundContract}
                value={depositValueFundContract}
                placeholder="0x000000000000000....."

              />

              
                  
        <Text className='Heading' marginTop={25}>{FundTokenName}</Text>
        <Text className='Heading'marginBottom={25} marginTop={15}>{fundTokensymbol}</Text>
                </div>

              )}


    {showFundContinueBtn && (
                <div className="toggle-container">
                      <button className={`toggle-button ${funderManageValue === 'fund' ? 'active' : ''}`} onClick={() => handleFundContinueYes()}>
                        Continue
                      </button>
                      
                </div>
    )}


{showFundForm && (
                  
    
                  <Box className='cardtxt' marginBottom="10px">
                     <Text className='Heading'>Funding For Token</Text>
                     <Text className='nftMintsmall'>{baseFundName}</Text>
                   


                   <Text className='nftMintsmall'>Dealix Name</Text>
                    <input
                      id="project-name"
                      type="text"
                      className="form-control"
                      onChange={handleDepositChangeName}
                      value={depositValueName}
                      placeholder="50% Bonus"
                    />

<Text className='nftMintsmall'>Dealix Percent</Text>
                    <input
                      id="telegram-handle"
                      type="text"
                      className="form-control"
                     onChange={handleDepositChangeBonus}
                     value={depositValueBonus}
                      placeholder="10"
                    />

<Text className='nftMintsmall'>Reward Pool Value</Text>
                    <input
                      id="liquidity-holdings"
                      type="text"
                      className="form-control"
                     onChange={handleDepositChangeEthRwd}
                     value={depositValueEthRwd}
                      placeholder="10000"
                    />

<Text className='nftMintsmall'>Vesting Time In Hours</Text>
                    <input
                      id="vest-time"
                      type="text"
                      className="form-control"
                     value={vestedInputValue}
                     onChange={handleVestedInputChange}
                      placeholder="Vesting In Hours"
                    />

                    <br />
                    <Text className="nftMintsmall">Your Reward Type</Text>
                    <div className="toggle-container">
                    <button
                        className={`toggle-button ${toggleFundRewardValue === 'ETH' ? 'active' : ''}`}
                        onClick={() => handleFundRewardToggle('ETH')}
                      >
                        ETH
                      </button>
                      <button
                        className={`toggle-button ${toggleFundRewardValue === 'TOKENS' ? 'active' : ''}`}
                        onClick={() => handleFundRewardToggle('TOKENS')}
                      >
                        TOKENS
                      </button>
                    </div>

                    <br />
                    <Button
                              className='btnsubmit1'
                              border='1px'
                              borderRadius={30}
                              variant='transparent'
                              marginInline={2}
                              fontSize="15px"
                              colorScheme='blue'
                              color='#E9D8FD' onClick={() => handleButtonClick(vote.baseToken)}>
                      <font>
                        <b>Fund & Launch Dealix</b>
                      </font>
                    </Button>
                  
                  </Box>
                  
                )}


        


   </Box>

        
      



  )}


{funderManageManage && (

<Box className='cardtxt' marginBottom="10px">
<Text className='Heading'>Managing For Token</Text>
<Text className='FullHeading'>{vote.tokenName}</Text>
<br></br>

<div>
        <Text className='SHeading'>{discountName}</Text>
        <Text className='SHeading'>{discountVolume}</Text>
        <Text className='SHeading'>{discountRewardpercent}</Text>
        <Text className='SHeading'>{discountExpiry}</Text>
        <Text className='SHeading'>{discountRemaining}</Text>
        
                            <Button
                              className='btnsubmit1'
                              border='1px'
                              borderRadius={30}
                              variant='transparent'
                              marginInline={2}
                              fontSize="15px"
                              colorScheme='blue'
                              color='#E9D8FD' onClick={() => RequestClose(vote.baseToken)}>
                      <font>
                        <b>Close Dealix</b>
                      </font>
                    </Button>

                    <Button
                              className='btnsubmit1'
                              border='1px'
                              borderRadius={30}
                              variant='transparent'
                              marginInline={2}
                              fontSize="15px"
                              colorScheme='blue'
                              color='#E9D8FD' onClick={() => CloseDiscount(vote.baseToken)}>
                      <font>
                        <b>Remove Funds</b>
                      </font>
                    </Button>
                    
      </div>
        


</Box>

)}
















                        
                        <Flex justify="center">
                          <Stack order={{ base: '2', md: '1' }} spacing="5px">
                            <Button
                              className='btnsubmit1'
                              border='1px'
                              borderRadius={30}
                              variant='transparent'
                              marginInline={2}
                              fontSize="15px"
                              colorScheme='blue'
                              color='#E9D8FD'
                              onClick={() => {
                                // Reset state variables

                                // Close the modal
                                close();
                                handleFundClose();
                              }}
                            >
                              <b>Close Window</b>
                            </Button>
                          </Stack>
                        </Flex>
                      </div>
                    )}
                  </Popup>
                </Card>
                
              )}
            </div>
          ))
          
        )}
        
      </Grid>
      

    </Card>
    </Box>
    


    <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
      <Box className='cardtxt' marginBottom="10px">
        <Text className='FullHeading'>Quick Help</Text>
        <div className="toggle-container">
                      <button className={`toggle-button ${funderHelpValue === 'starting' ? 'active' : ''}`} onClick={() => handleFunder('starting')}>
                        Starting

                      </button>
                      <button className={`toggle-button ${funderHelpValue === 'closing' ? 'active' : ''}`} onClick={() => handleFunder('closing')}>
                        Closing

                      </button>
        </div>
        {showFunderStart && (

            <>
            <Text className='Heading' marginBottom="15px" marginTop="15px">How Do I Fund A Discount?</Text>

            <Box className='cardtxt' marginBottom="15px">
            <li className='SHeading' marginBottom="15px">Click Manage On The Dealix</li>
            <li className='SHeading' marginBottom="15px">Click Fund Dealix</li>
            <li className='SHeading' marginBottom="15px">Enter Contract Address</li>
            <li className='SHeading' marginBottom="15px">Fill In The Form & Click Fund & Launch</li>
            <li className='SHeading' marginBottom="15px">Note: ERC-20 Token Rewards Will Need 2 Transactions</li>
            <li className='SHeading' marginBottom="15px">Note: One For Token Transfer Approval</li>
            <li className='SHeading' marginBottom="15px">Note: One For Dealix Creation</li>
            </Box>
            </>

        )}

          {showFunderClose && (

          <>
          <Text className='Heading' marginBottom="15px" marginTop="15px">How Do I Close A Dealix?</Text>

          <Box className='cardtxt' marginBottom="15px">
          <li className='SHeading' marginBottom="15px">Click Manage On The Dealix</li>
          <li className='SHeading' marginBottom="15px">Click Manage Dealix</li>
          <li className='SHeading' marginBottom="15px">Click Close Dealix</li>
          <li className='SHeading' marginBottom="15px">Note: There is a 1 Day Wait After Close Confirmation</li>
          <li className='SHeading' marginBottom="15px">After The Waiting Period Click Remove Funds</li>
          </Box>
          </>

          )}

      </Box>
      
    </Card>






    </Grid>
)}





























                  </Card>



                  </Grid>

              </Card>

            </Grid>
            
           
          </Card>
        </Grid>


      </Box>

    </Box>


  );
};
export default TopHeader;