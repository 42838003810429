import React, { useState } from 'react';
import { Flex, Box, Image, Link, IconButton } from '@chakra-ui/react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import logo from '../Images/baseLogo.png';
import { FaTwitter, FaTelegram, FaGithub } from 'react-icons/fa'; // Ensure react-icons is installed

const Navbar = () => {
    const { isConnected } = useWeb3ModalAccount();
    const [connected, setConnected] = useState(false);

    const connectWallet = () => {
        setConnected(true); 
    };

    return (
        <Flex 
            justify="space-between" 
            align="center" 
            mb="10px" 
            py="25px" 
            position="relative" /* Changed from fixed to relative */
            width="100%" 
            px="20px" /* Padding for spacing on sides */
            backgroundColor="#010100" /* Optional: Add background color */
        >
            {/* Left: Logo */}
            <Box>
                <Image src={logo} alt="Logo" boxSize="50px" />
            </Box>

            {/* Right: Social Icons */}
            <Flex align="center" gap="15px">
                <Link href="https://twitter.com/dealix_io" isExternal>
                    <IconButton
                        icon={<FaTwitter />}
                        aria-label="Twitter"
                        size="lg"
                        variant="ghost"
                        color="white"
                    />
                </Link>
                <Link href="https://t.me/DealixEntry" isExternal>
                    <IconButton
                        icon={<FaTelegram />}
                        aria-label="Telegram"
                        size="lg"
                        variant="ghost"
                        color="white"
                    />
                </Link>
               
            </Flex>
        </Flex>
    );
};

export default Navbar;
