import { Box, Flex, Grid, Image, Card, CardBody, CardFooter, Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, { startTransition, useEffect, useState } from 'react';
import Slider from "react-slick";
import bigInt from 'big-integer';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import discount from '../artifacts/contracts/Discount.sol/Discount.json';
import token from '../artifacts/contracts/Token.sol/Token.json';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import '../Styles/Home.css';
import 'react-datepicker/dist/react-datepicker.css';
import bottomImg from '../Images/separator-top copy.svg';
import topImg from '../Images/separator-bottom copy.svg';
import axios from 'axios';
import Bottleneck from 'bottleneck';
import Twitter from '../Images/icons/twitter.png'
import Telegram from '../Images/icons/telegram.png'
import Website from '../Images/icons/globe.png'
import BaseLogo from '../Images/baseLogo.png'
import Chart from '../Images/icons/chart.png'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Leaderboard from './leaderboard';



const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Show 3 cards at a time
  centerMode: true, // Enable center mode
  centerPadding: '60px', // Adjust padding to control the visibility of next/previous cards
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, // Show 1 card on small screens
        centerPadding: '20px', // Adjust padding for small screens
      },
    },
  ],
};




const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};




const DiscountContractAddress = '0xaDf42C5371CD1fd28Cf82164D585E43ac7e5414d';



const apiKey = 'cxuZqfqcTi7h0lDURewlz7De3D6ggtww8hFX1Lnn'; // Replace with your actual API key

// Set up rate limiter
const limiter = new Bottleneck({
  minTime: 500, // Minimum time between requests in milliseconds
  maxConcurrent: 1 // Number of concurrent requests
});





const Information = () => {



  const techFacts = [
    "Anyone Can Become An Affiliate With Dealix",
    "Dealix is Powered By An ERC-721 Contract",
    "The Dealix Front End Is 100% Server Free!!",
    "Dealix Is 99.9% Decentralized, Only The Front End Is Hosted",
    "Dealix Is Live On ETH & BASE With More Coming",
    "Dealix Offers Secure and Immutable Transactions",
    "Dealix Affiliates Get Paid Every Transaction On Their Deal ID's",
    "Dealix Affiliates Can Earn Income 24/7",
    "Dealix Lets Affiliates Earn Without Any Upfront Investment, Just Gas Fees",
    "Dealix Helps Affiliates Build a Sustainable Income Stream",
   
  ];

  const getRandomFact = () => {
    return techFacts[Math.floor(Math.random() * techFacts.length)];
  };

 

  const [connected, setConnected] = useState(false);

  const connectWallet = () => {
    setConnected(true);
  };

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  useEffect(() => {
    fetchUserDiscounts()
  }, [address])

    const [showBasicBuy, setShowBasicBuy] = useState(true);
    const [showBasicAffilitate, setShowBasicAffilitate] = useState();
    const [showBasicClaim, setShowBasicClaim] = useState();
    const [showReadingData, setShowBasicData] = useState();
    const [basicValue, setBasicsValue] = useState('buy');
    const [newestTokensId, setNewestTokensId] = useState([]);
    const [randomFact, setRandomFact] = useState('');




  
      const [isFetching, setIsFetching] = useState(true);

      useEffect(() => {
        setRandomFact(getRandomFact());
      }, [isFetching]);


      useEffect(() => {
        fetchUserDiscounts()
        setIsFetching(true)
      }, [address]);

    
      useEffect(() => {
        if (newestTokensId.length > 0) {
          setIsFetching(false);
        }
      }, [newestTokensId]);
    

    const handleBasics = (value) => {
        if (value === 'buy') {
          setBasicsValue(value)
          setShowBasicClaim(false)
          setShowBasicBuy(true)
          setShowBasicAffilitate(false)
          setShowBasicData(false)
          console.log('info')
      
    
        }
    
        if (value === 'affiliate') {
          setBasicsValue(value)
          setShowBasicAffilitate(true)
          setShowBasicBuy(false)
          setShowBasicClaim(false)
          setShowBasicData(false)
  
          console.log('buy')
        }
    
    
        if (value === 'claim') {
          setBasicsValue(value)
          setShowBasicClaim(true)
          setShowBasicBuy(false)
          setShowBasicAffilitate(false)
          setShowBasicData(false)
          console.log('setup')
        }

        if (value === 'data') {
          setBasicsValue(value)
          setShowBasicData(true)
          setShowBasicClaim(false)
          setShowBasicBuy(false)
          setShowBasicAffilitate(false)
          console.log('data')
        }
      }

const [AddQuickID, setDepositQickID] = useState();
    const handleDepositQuickID = (event) => {
      const newValue = event.target.value;
      setDepositQickID(newValue);
    };
  
const [AddQuickETH, setDepositQickETH] = useState();
    const handleDepositQuickETH = (e) => {
      setDepositQickETH(e.target.value);
    };
  
const [AddQuickSlip, setDepositQickSlip] = useState();
    const handleDepositQuickSlip = (e) => {
      setDepositQickSlip(e.target.value);
    };

    async function fetchUserDiscounts() {
      if (address) {
      const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const discountContract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        try {
          if (signer) {
            console.log("Fetching");
            const tokenIds = await discountContract.getActiveDiscounts(signer);
            const activeRemoveVotesPromises = tokenIds.map(async (tokenId) => {
              const voteData = await discountContract.discounts(tokenId);
              const contractAddress = voteData[3].toString();
              const funderAddress = voteData[1];
              const Vestingtype = voteData[10].toString() === "true"; // Convert to boolean if needed
    
              let tokenName = "Ready To Setup";
              let tokenSym = "N/A";
              let tokenLogo = "";
              let tokenTwitter = "";
              let tokenWebsite = "";
              let tokenTelegram = "";
              let tokenColor = "#14ebff"; // Default color
              let tokenMc = "";
              let tokenChart = "";
              let price = "N/A";
              let price5min = "";
              let price1hr = "";
              let price6hr = "";
              let price24hr = "";
              let color5min = "";
              let color1hr = "";
              let color6hr = "";
              let color24hr = "";
              let vestingType = "";
              let buyTax = "N/A";
              let sellTax = "N/A";
              let buyTaxColor = "#14ebff";
              let sellTaxColor = "#14ebff";
              let scam = "N/A";
              let scamColor = "";
              let isOpenSource = "N/A";
              let isHoneypot = "N/A";
              let isHoneypotColor = "#14ebff";
              let isMintable = "N/A";
              let isProxy = "N/A";
              let slippageModifiable = "N/A";
              let isBlacklisted = "N/A";
              let isContractRenounced = "N/A";
              let renouncedColor = "#ff2a2a";
              let decimals = "";
              
    
              if (funderAddress !== "0x0000000000000000000000000000000000000000") {
                tokenName = "Awaiting Funder";
                tokenColor = "#ffa63e"; // Change color based on condition
              }
    
              if (contractAddress !== "0x0000000000000000000000000000000000000000") {
                const TempContract = new ethers.Contract(contractAddress, token.abi, signer);
                const newName = await TempContract.name();
                const newSym = await TempContract.symbol();
    
                tokenName = newName;
                tokenSym = newSym;
                const tokenInfo = await fetchTokenData(contractAddress); // Fetch the token data
                tokenLogo = tokenInfo.tokenLogo;
                
                tokenTelegram = tokenInfo.tokenTelegram;
                tokenTwitter = tokenInfo.tokenTwitter;
                tokenWebsite = tokenInfo.tokenWebsite;
                decimals = tokenInfo.tokenDecimals;
                console.log(decimals)
                tokenMc = tokenInfo.marketCap;
                tokenColor = "#8DE4FF";
                tokenChart = tokenInfo.tokenChart;
    
                price5min = Number(tokenInfo.price5min).toFixed(3); // Formatting to 3 decimal places
                price1hr = Number(tokenInfo.price1hr).toFixed(3); // Formatting to 3 decimal places
                price6hr = Number(tokenInfo.price6hr).toFixed(3); // Formatting to 3 decimal places
                price24hr = Number(tokenInfo.price24hr).toFixed(3); // Formatting to 3 decimal places
                color5min = tokenInfo.color5min;
                color1hr = tokenInfo.color1hr;
                color6hr = tokenInfo.color6hr;
                color24hr = tokenInfo.color24hr;
                buyTax = tokenInfo.buyTax;
                sellTax = tokenInfo.sellTax;
                scam = tokenInfo.scam.toUpperCase();
                isOpenSource = tokenInfo.isOpenSource.toUpperCase();
                isHoneypot = tokenInfo.isHoneypot.toUpperCase();
                isMintable = tokenInfo.isMintable.toUpperCase();
                isProxy = tokenInfo.isProxy.toUpperCase();
                slippageModifiable = tokenInfo.slippageModifiable.toUpperCase();
                isBlacklisted = tokenInfo.isBlacklisted.toUpperCase();
                isContractRenounced = tokenInfo.isContractRenounced.toUpperCase();
    
                renouncedColor = isContractRenounced === "YES" ? "#01fc01" : "#ff2a2a";
                isHoneypotColor = isHoneypot === "YES" ? "#ff2a2a" : "#01fc01";
    




                const startingRewards = voteData[6].toString(); // Start with wei, convert later
                const spentRewards = voteData[8].toString(); // Start with wei, convert later
                const remainingRewards = parseFloat(startingRewards) - parseFloat(spentRewards);
                const tokenPrice = parseFloat(tokenInfo.price);
                console.log(tokenPrice,"Token Price")
                const weth = tokenInfo.wethPrice;
                console.log(weth,"ETH Price")

          
                const percentageString = voteData[4].toString();
                const percentageValue = parseFloat(percentageString); // Convert to float
                console.log(percentageValue + "this si % value of discount")
                const percentageAsDecimal = percentageValue / 100; // Convert to decimal

      

                if (Vestingtype) {
                  // Convert remainingRewards from wei to Ether
                  const remainingRewardsInEther = parseFloat(formatUnits(remainingRewards.toString(), 18)); 
                  console.log(remainingRewardsInEther, "Remaining Rewards In ETHER");
                
                  // Calculate the ETH you need to spend to get the full rebate
                  // Divide the remaining rewards by the rebate percentage
                  const ethToSpendInEther = remainingRewardsInEther / percentageAsDecimal;
                
                  // Format the result to a fixed number of decimal places (5 in this case)
                  price = ethToSpendInEther.toFixed(5) + " ETH";
                  console.log(price, "ETH needed to close DISCOUNT");
                }
                
                
                
                
                
                
                
                
                
                
                
                else {
             

                

                  // Convert remaining rewards to the correct token units
                  const remainingRewardsTokens = parseFloat(formatUnits(remainingRewards.toString(), decimals)); 
                  console.log(remainingRewardsTokens + " This is Remaining Tokens");
                  
                  // Check if tokenPrice and weth are valid numbers
                  console.log(tokenPrice + " This is Token Price in USDT");
                  console.log(weth + " This is ETH price in USDT");
                  
                  // Check for invalid values
                  if (isNaN(remainingRewardsTokens) || isNaN(tokenPrice) || isNaN(weth)) {
                    console.error("One of the values is not a valid number. Check remainingRewardsTokens, tokenPrice, or weth.");
                  } else {
                    // Calculate the ETH value needed based on the token price and ETH price
                    const EthVal = (remainingRewardsTokens * tokenPrice) / weth;
                    console.log(EthVal + " This is the ETH value needed to end");
                  
                    // Format the result to a fixed number of decimal places (4 in this case)
                    price = EthVal.toFixed(4) + " " + "ETH";
                    console.log(price, "ETH needed to close DISCOUNT in Tokens");
                  }
                  
                  
                }






    
                if (parseFloat(buyTax) === 0) {
                  buyTaxColor = "#01fc01";
                } else if (parseFloat(buyTax) > 0 && parseFloat(buyTax) < 5) {
                  buyTaxColor = "#ffd600";
                } else if (parseFloat(buyTax) >= 5) {
                  buyTaxColor = "#ff2a2a";
                }
    
                if (parseFloat(sellTax) === 0) {
                  sellTaxColor = "#01fc01";
                } else if (parseFloat(sellTax) > 0 && parseFloat(sellTax) < 5) {
                  sellTaxColor = "#ffd600";
                } else if (parseFloat(sellTax) >= 5) {
                  sellTaxColor = "#ff2a2a";
                }
              } else {
                tokenName = "Ready To Fund";
                tokenColor = "Yellow"; // Default color
              }
    
              vestingType = Vestingtype ? "ETH" : "Tokens";
    
              return {
                tokenId: "Dealix ID # " + tokenId.toString(),
                baseToken: tokenId,
                owner: voteData[0],
                funder: voteData[1],
                name: voteData[2],
                contractAddress: voteData[3],
                bonusPercentage: voteData[4].toString() + " %",
                expirationTime: voteData[5],
                price: price,
                tknVestTime: voteData[7].toString() + " Hours",
                rewardsSent: voteData[8],
                volume: parseFloat(formatUnits(voteData[9], 18)).toFixed(3) + " ETH",
                tknVestType: vestingType,
                tokenName: tokenName,
                tokenLogo: tokenLogo,
                tokenTG: tokenTelegram,
                tokenTW: tokenTwitter,
                tokenWS: tokenWebsite,
                tokenMC: tokenMc,
                decimals: decimals,
                tokenColor: tokenColor,
                chart: tokenChart,
                price5min: price5min,
                price1hr: price1hr,
                price6hr: price6hr,
                price24hr: price24hr,
                color5min: color5min,
                color1hr: color1hr,
                color6hr: color6hr,
                color24hr: color24hr,
                buyTax: buyTax,
                buyTaxColor: buyTaxColor,
                sellTax: sellTax,
                sellTaxColor: sellTaxColor,
                scam: scam,
                scamColor: scamColor,
                isOpenSource: isOpenSource,
                isHoneypot: isHoneypot,
                isHoneypotColor: isHoneypotColor,
                isMintable: isMintable,
                isProxy: isProxy,
                slippageModifiable: slippageModifiable,
                isBlacklisted: isBlacklisted,
                isContractRenounced: isContractRenounced,
                renouncedColor: renouncedColor
              };
            });
    
            const votesData = await Promise.all(activeRemoveVotesPromises);
            setNewestTokensId(votesData); // Ensure `newestTokensId` state is updated with complete data
          }
        } catch (err) {
          console.log(err.message);
        }
      } else {
        setNewestTokensId(["No Deals Found..."]);
      }
    }
    
    
    


    const fetchTokenData = async (contractAddress) => {
      const url = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}`;
      const marketCapUrl = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/info`;
      const priceUrl = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/price`;
      const wethUrl = `https://public-api.dextools.io/trial/v2/token/eth/0x4200000000000000000000000000000000000006/price`;
      const chart = `https://www.dextools.io/app/en/eth/pair-explorer/${contractAddress}`;
      const audit = `https://public-api.dextools.io/trial/v2/token/eth/${contractAddress}/audit`;
    
      const fetchWithRetry = async (url) => {
        let attempts = 0;
        const maxAttempts = 5;
        const retryDelay = 10000;
    
        while (attempts < maxAttempts) {
          try {
            const response = await axios.get(url, {
              headers: {
                'x-api-key': apiKey, 
                'accept': 'application/json'
              }
            });
            return response.data.data;
          } catch (error) {
            if (error.response && error.response.status === 429) {
              attempts++;
              const delay = retryDelay * attempts;
              console.warn(`Rate limit exceeded. Retrying in ${delay} ms...`);
              await new Promise(resolve => setTimeout(resolve, delay));
            } else {
              throw error;
            }
          }
        }
        throw new Error('Max retry attempts reached');
      };
    
      try {
        const [data, marketCapData, priceData,wethData, auditData] = await Promise.all([
          fetchWithRetry(url),
          fetchWithRetry(marketCapUrl),
          fetchWithRetry(priceUrl),
          fetchWithRetry(wethUrl),
          fetchWithRetry(audit)
        ]);
    
        const buyTax = auditData.buyTax?.max !== undefined ? String(auditData.buyTax.max) : 'N/A';
        const sellTax = auditData.sellTax?.max !== undefined ? String(auditData.sellTax.max) : 'N/A';
    
        const scam = auditData.isPotentiallyScam || '';
        const isOpenSource = auditData.isOpenSource || '';
        const isHoneypot = auditData.isHoneypot || '';
        const isMintable = auditData.isMintable || '';
        const isProxy = auditData.isProxy || '';
        const slippageModifiable = auditData.slippageModifiable || '';
        const isBlacklisted = auditData.isBlacklisted || '';
        const isContractRenounced = auditData.isContractRenounced || '';
    
        const tokenLogo = data.logo || '';
        const tokenDecimals = data.decimals || '';
        const tokenTelegram = data.socialInfo?.telegram || '';
        const tokenTwitter = data.socialInfo?.twitter || '';
        const tokenWebsite = data.socialInfo?.website || '';
    
        const price = priceData.price ? priceData.price : "0.00";
        const wethPrice =  wethData.price ? wethData.price : "0.00";

        const price5min = priceData.variation5m ? priceData.variation5m.toFixed(2) : '0.00';
        const price1hr = priceData.variation1h ? priceData.variation1h.toFixed(2) : '0.00';
        const price6hr = priceData.variation6h ? priceData.variation6h.toFixed(2) : '0.00';
        const price24hr = priceData.variation24h ? priceData.variation24h.toFixed(2) : '0.00';
    
        let color5min = '';
        let color1hr = '';
        let color6hr = '';
        let color24hr = '';
    
        if (parseFloat(price5min) <= 0) {
          color5min = '#ff2a2a';
        } else if (parseFloat(price5min) < 2) {
          color5min = '#ffd600';
        } else {
          color5min = '#01fc01';
        }
    
        if (parseFloat(price1hr) <= 0) {
          color1hr = '#ff2a2a';
        } else if (parseFloat(price1hr) < 4) {
          color1hr = '#ffd600';
        } else {
          color1hr = '#01fc01';
        }
    
        if (parseFloat(price6hr) <= 0) {
          color6hr = '#ff2a2a';
        } else if (parseFloat(price6hr) < 4) {
          color6hr = '#ffd600';
        } else {
          color6hr = '#01fc01';
        }
    
        if (parseFloat(price24hr) <= 0) {
          color24hr = '#ff2a2a';
        } else if (parseFloat(price24hr) < 4) {
          color24hr = '#ffd600';
        } else {
          color24hr = '#01fc01';
        }
    
        let marketCap = marketCapData.fdv || 0;
        let formattedMarketCap = '';
    
        if (marketCap === 0) {
          formattedMarketCap = 'Not Available';
        } else {
          formattedMarketCap = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(marketCap);
        }
    
        return {
          tokenLogo,
          tokenTelegram,
          tokenTwitter,
          tokenWebsite,
          marketCap: formattedMarketCap,
          tokenChart: chart,
          tokenDecimals,
          price,
          price5min,
          price1hr,
          price6hr,
          price24hr,
          color5min,
          color1hr,
          color6hr,
          color24hr,
          wethPrice,
          buyTax,
          sellTax,
          scam,
          isOpenSource,
          isHoneypot,
          isMintable,
          isProxy,
          slippageModifiable,
          isBlacklisted,
          isContractRenounced
        };
    
      } catch (error) {
        console.error('Error fetching token data:', error);
        return {
          tokenLogo: '',
          tokenTelegram: '',
          tokenTwitter: '',
          tokenWebsite: '',
          marketCap: '',
          tokenChart: '',
          price5min: '',
          price1hr: '',
          price6hr: '',
          price24hr: '',
          color5min: '',
          color1hr: '',
          color6hr: '',
          color24hr: '',
          buyTax: 'N/A',
          sellTax: 'N/A',
          scam: '',
          isOpenSource: '',
          isHoneypot: '',
          isMintable: '',
          isProxy: '',
          slippageModifiable: '',
          isBlacklisted: '',
          isContractRenounced: ''
        };
      }
    };
    
    
    
    
    
    
    


    async function buyWithDiscount() {
      try {

        const id = AddQuickID.toString()
        const eth = AddQuickETH;
        const slip = AddQuickSlip.toString()
        
        const wei = parseEther(eth);
        console.log(id)
        console.log(wei)
        console.log(slip)
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(DiscountContractAddress, discount.abi, signer);
    
        const transaction = await contract.buyWithDiscountv2(id, wei, slip, { value: wei});
        Swal.fire({
          icon: 'info',
          title: 'Transaction Pending',
          text: `Purchasing Discount ${AddQuickID} for ${AddQuickETH}. Please wait...`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });
    
        const receipt = await transaction.wait();
        console.log('Transaction successful:', receipt);
        Swal.close(); // Close the pending alert
        Swal.fire({
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Purchase Successful',
        });
      } catch (error) {
        console.error('Transaction failed:', error);
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason || error.message,
        });
      }
    }



return(

  <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="1px">
             
          <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap="20px">

          <Box> 
          <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
            <Box className='cardtxt' marginBottom="10px">
                <Text className='FullHeading'>The Basic's</Text>
            </Box>

            <div className="toggle-container">
              <button className={`toggle-button ${basicValue === 'buy' ? 'active' : ''}`} onClick={() => handleBasics('buy')}>
                Buy

              </button>
              <button className={`toggle-button ${basicValue === 'affiliate' ? 'active' : ''}`} onClick={() => handleBasics('affiliate')}>
                Affiliate

              </button>
              
              <button className={`toggle-button ${basicValue === 'claim' ? 'active' : ''}`} onClick={() => handleBasics('claim')}>
                Claim
              </button>

              <button className={`toggle-button ${basicValue === 'data' ? 'active' : ''}`} onClick={() => handleBasics('data')}>
               Help
              </button>
              
              </div>

              {showReadingData && (
                <>
                    <Text className='Heading' marginBottom="15px" marginTop="15px">The Basics For Acronyms</Text>
                  
                  <Box className='cardtxt' marginBottom="15px">
                    <li className='SHeading' marginBottom="15px">Viewing Data RP- Rewards Percentage</li>
                    <li className='SHeading' marginBottom="15px">Viewing Data RT- Rewards Type</li>
                    <li className='SHeading' marginBottom="15px">Viewing Data VT- Vesting Time</li>
                    <li className='SHeading' marginBottom="15px">To Update Logo & Data Please Update Dextools</li>
                  </Box>
                </>
              )}

              {showBasicBuy && (
                <>
                    <Text className='Heading' marginBottom="15px" marginTop="15px">How Do I Quick Buy A Deal?</Text>
                  
                  <Box className='cardtxt' marginBottom="15px">
                    <li className='SHeading' marginBottom="15px">Enter The Dealix ID</li>
                    <li className='SHeading' marginBottom="15px">Enter ETH Amount</li>
                    <li className='SHeading' marginBottom="15px">Enter Slippage Amount</li>
                    <li className='SHeading' marginBottom="15px">Click Quick Buy & Submit Transaction</li>
                    <li className='SHeading' marginBottom="15px">You Can Only Buy A Deal Once Per Cycle</li>
                    
                  </Box>
                </>
              )}

              {showBasicAffilitate && (
                <>
                    <Text className='Heading' marginBottom="15px" marginTop="15px">How Do I Become An Affiliate?</Text>
                  
                  <Box className='cardtxt' marginBottom="15px">
                    <li className='SHeading' marginBottom="15px">Head To The Affiliate Page</li>
                    <li className='SHeading' marginBottom="15px">Mint A Dealix Card</li>
                    <li className='SHeading' marginBottom="15px">Assign A Funder/Project Owners Wallet</li>
                    <li className='SHeading' marginBottom="15px">Sit Back & Collect Rewards</li>
                  </Box>
                </>
              )}

              {showBasicClaim && (
                <>
                    <Text className='Heading' marginBottom="15px" marginTop="15px">How Do I Claim My Rewards, If Vested?</Text>
                  
                  <Box className='cardtxt' marginBottom="15px">
                    <li className='SHeading' marginBottom="15px">Head To Buy/Claim Page</li>
                    <li className='SHeading' marginBottom="15px">Under Claim Enter Dealix ID</li>
                    <li className='SHeading' marginBottom="15px">Check Time Remaining</li>
                    <li className='SHeading' marginBottom="15px">If Vesting Complete Click Claim</li>
                   
                  </Box>
                </>
              )}
     
            
         
           </Card>
           </Box>
          

           <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
            <Box className='cardtxt' marginBottom="10px">
                <Text className='FullHeading'>Quick Purchase</Text>
            </Box>
           
            <Box className='cardtxt' marginBottom="10px">
            <Text className='SHeading' >Enter Dealix ID</Text>
            </Box>
            <div>
              <div className="toggle-container">
                <input
                  id="discount-id"
                  type="text"
                  className="form-control"
                  onChange={handleDepositQuickID}
                  value={AddQuickID}
                  placeholder="Dealix ID"
                />
              </div>
              <Box className='cardtxt' marginBottom="25px"></Box>

         

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter ETH Amount</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="eth-amount"
                  type="text"
                  className="form-control"
                  onChange={handleDepositQuickETH}
                  value={AddQuickETH}
                  placeholder="0.25 ETH"
                />
              </div>
              <Box className='cardtxt' marginBottom="25px"></Box>

          

              <Box className='cardtxt' marginBottom="10px">
                <Text className='SHeading'>Enter Slippage</Text>
              </Box>
              <div className="toggle-container">
                <input
                  id="slippage"
                  type="text"
                  className="form-control"
                  onChange={handleDepositQuickSlip}
                  value={AddQuickSlip}
                  placeholder="5%"
                />
              </div>
              <Button 
                  className='btnsubmit1' 
                  border='1px' 
                  marginTop="15px"
                  borderRadius={30} 
                  variant='transparent' 
                  marginInline={2}  
                  fontSize="15px" 
                  colorScheme='blue' 
                  color='#E9D8FD' 
                  onClick={() => buyWithDiscount()}
                >
                  <b>Buy Fast</b>
                </Button>
                <br></br>
            </div>

           </Card>




         
        </Grid>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap="9px">
      <Card className='NFTHead' textAlign="center" borderRadius="30px" fontSize="30px" fontWeight="bold">
        <Box maxWidth="1200px" margin="0 auto" padding="40px 20px">

          {!address ? (
            <Flex direction="row" wrap="nowrap" gap="9px" justifyContent="center">
              <Card className="vote-card-remove" style={{ margin: '15px 10px !important', padding: '20px', height: '100%' }}>
                <Box className="card-header10">
                  <Text className='FullHeading'>Please Connect Wallet</Text>
                  <Text marginTop={10} className='Heading'>Random Fact About Dealix</Text>
                  <Text marginTop={10}>{randomFact}</Text>
                </Box>
              </Card>
            </Flex>
          ) : (
            isFetching ? (
              <Flex direction="row" wrap="nowrap" gap="9px" justifyContent="center">
                <Card className="vote-card-remove" style={{ margin: '15px 10px !important', padding: '20px', height: '100%' }}>
                  <Box className="card-header10">
                    <Text className='FullHeading'>Fetching data, please wait...</Text>
                    <Text marginTop={10} className='Heading'>Random Fact About Dealix</Text>
                    <Text marginTop={10}>{randomFact}</Text>
                  </Box>
                </Card>
              </Flex>
            ) : (
              <Box width={{ base: '95%', md: '100%' }} margin="0 auto">
                <Slider {...settings}>
                  {newestTokensId.length === 0 ? (
                    <Flex direction="row" wrap="nowrap" gap="9px" justifyContent="center">
                      <Card className="vote-card-remove" style={{ margin: '15px 10px !important', padding: '20px', height: '100%' }}>
                        <Box className="card-header10">
                          <Text>No Deals Found</Text>
                        </Box>
                      </Card>
                    </Flex>
                  ) : (
                    newestTokensId.map((vote, index) => (
                      <div key={index} style={{ padding: '10px' }}>
                        <Card className="vote-card-remove" style={{ margin: '15px 10px !important', padding: '20px', height: '100%' }}>
                          <Box className="card-header10">
                            <Text className='SHeading'>{vote.tokenId}</Text>
                            <Text className='SHeading'  color={vote.tokenColor}>{vote.tokenName}</Text>
                          </Box>
                          <Box className="card-header10" marginTop="15px">
                            <Grid templateColumns="repeat(3, 1fr)" gap={2} justifyContent="center" alignItems="center">
                              <Box textAlign="center">
                                <Text className='SLHeading'>RT</Text>
                                <Text className='SMDHeading'>{vote.tknVestType}</Text>
                              </Box>
                              <Box textAlign="center">
                                <Text className='SLHeading'>RP</Text>
                                <Text className='SMDHeading'>{vote.bonusPercentage}</Text>
                              </Box>
                              <Box textAlign="center">
                                <Text className='SLHeading'>VT</Text>
                                <Text className='SMDHeading'>{vote.tknVestTime}</Text>
                              </Box>
                            </Grid>
                            <Box className="card-header10" marginTop={15}>
                              <Text className='SLHeading'>Deal Volume</Text>
                              <Text className='SMDHeading'>{vote.volume}</Text>
                            </Box>
                            <Box className="card-header10" marginTop={15}>
                              <Text className='SLHeading'>Max ETH Buy Amount</Text>
                              <Text className='SMDHeading'>{vote.price}</Text>
                            </Box>
                          </Box>
                          <Box className='cardtxt' marginBottom="10px">
                            {vote.tokenLogo ? (
                              <img
                                className='bnbpool'
                                src={vote.tokenLogo}
                                alt="Token Logo"
                                style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                              />
                            ) : (
                              <img
                                className='bnbpool'
                                src={BaseLogo}
                                alt="Token Logo"
                                style={{ objectFit: 'contain' }}
                              />
                            )}
                            <Box className="card-header10" marginTop={15}>
                              <Text className='SMHeading'>MarketCap</Text>
                              <Text className='SMHeading'>{vote.tokenMC}</Text>
                            </Box>

                            <Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {vote.buyTax !== undefined && vote.buyTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: vote.buyTaxColor }}>{vote.buyTax}%</Text>
      </Box>
    )}
    {(vote.buyTax === undefined || vote.buyTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Buy Tax</Text>
        <Text className='SLHeading'style={{ color: vote.buyTaxColor }}>{vote.buyTax}</Text>
      </Box>
    )}
    {vote.sellTax !== undefined && vote.sellTax !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: vote.sellTaxColor }}>{vote.sellTax}%</Text>
      </Box>
    )}
    {(vote.sellTax === undefined || vote.sellTax === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Sell Tax</Text>
        <Text className='SLHeading'style={{ color: vote.sellTaxColor }}>{vote.sellTax}</Text>
      </Box>
    )}
  </Grid>
</Box>

<Box className="card-header10" marginTop="15px">
  <Grid templateColumns="repeat(2, 1fr)" gap={0} justifyContent="center" alignItems="center">
    {vote.isContractRenounced !== undefined && vote.isContractRenounced !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: vote.renouncedColor }}>{vote.isContractRenounced}</Text>
      </Box>
    )}
    {(vote.isContractRenounced === undefined || vote.isContractRenounced === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>Renounced</Text>
        <Text className='SLHeading' style={{ color: vote.renouncedColor }}>{vote.isContractRenounced}</Text>
      </Box>
    )}
    {vote.isHoneypot !== undefined && vote.isHoneypot !== 'N/A' && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: vote.isHoneypotColor }}>{vote.isHoneypot}</Text>
      </Box>
    )}
    {(vote.isHoneypot === undefined || vote.isHoneypot === 'N/A') && (
      <Box textAlign="center">
        <Text className='SLHeading'>HoneyPot</Text>
        <Text className='SLHeading'style={{ color: vote.isHoneypotColor }}>{vote.isHoneypot}</Text>
      </Box>
    )}
  </Grid>
</Box>



                            <Box className="card-header10" marginTop="15px">
                              <Grid templateColumns="repeat(4, 1fr)" gap={0} justifyContent="center" alignItems="center">
                                {vote.price5min !== undefined && (
                                  <Box textAlign="center">
                                    <Text className='SLHeading'>5Min</Text>
                                    <Text className='SLHeading' style={{ color: vote.color5min }}>{vote.price5min}%</Text>
                                  </Box>
                                )}
                                {vote.price1hr !== undefined && (
                                  <Box textAlign="center">
                                    <Text className='SLHeading'>1h</Text>
                                    <Text className='SLHeading' style={{ color: vote.color1hr }}>{vote.price1hr}%</Text>
                                  </Box>
                                )}
                                {vote.price6hr !== undefined && (
                                  <Box textAlign="center">
                                    <Text className='SLHeading'>6h</Text>
                                    <Text className='SLHeading' style={{ color: vote.color6hr }}>{vote.price6hr}%</Text>
                                  </Box>
                                )}
                                {vote.price24hr !== undefined && (
                                  <Box textAlign="center">
                                    <Text className='SLHeading'>24h</Text>
                                    <Text className='SLHeading' style={{ color: vote.color24hr }}>{vote.price24hr}%</Text>
                                  </Box>
                                )}
                              </Grid>
                            </Box>
                            <Box className="token-info-card" marginTop="15px">
                              <Flex justifyContent="center" alignItems="center">
                                {vote.tokenTG && (
                                  <a href={vote.tokenTG} target="_blank" rel="noopener noreferrer">
                                    <Image src={Telegram} alt="Telegram" boxSize="25px" mr={2} />
                                  </a>
                                )}
                                {vote.tokenWS && (
                                  <a href={vote.tokenWS} target="_blank" rel="noopener noreferrer">
                                    <Image src={Website} alt="Website" boxSize="30px" mr={2} />
                                  </a>
                                )}
                                {vote.tokenTW && (
                                  <a href={vote.tokenTW} target="_blank" rel="noopener noreferrer">
                                    <Image src={Twitter} alt="Twitter" boxSize="25px" mr={2} />
                                  </a>
                                )}
                                {vote.chart && (
                                  <a href={vote.chart} target="_blank" rel="noopener noreferrer">
                                    <Image src={Chart} alt="Chart" boxSize="25px" />
                                  </a>
                                )}
                              </Flex>
                            </Box>
                          </Box>
                        </Card>
                      </div>
                    ))
                  )}
                </Slider>
              </Box>
            )
          )}

        </Box>
      </Card>
    </Grid>
  </Grid>












)}

export default Information;
