import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import abi from '../artifacts/contracts/Discount.sol/Discount.json';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';

import { Box, Text } from '@chakra-ui/react';

const contractAddress = '0xaDf42C5371CD1fd28Cf82164D585E43ac7e5414d';

const Leaderboard = () => {
  const [discounts, setDiscounts] = useState([]);
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(contractAddress, abi.abi, signer);

        const activeDiscountIds = await contract.getActiveDiscounts();
        let discountPromises = activeDiscountIds.map(id => contract.discounts(id));
        const discountDetails = await Promise.all(discountPromises);

        const discountsWithIds = discountDetails.map((discount, index) => {
          const volumeInEth = formatUnits(discount.volume, 18);
          return {
            id: activeDiscountIds[index],
            volume: volumeInEth,
            formattedVolume: parseFloat(volumeInEth).toFixed(2) + " ETH",
          };
        });

        const sortedDiscounts = discountsWithIds.sort((a, b) => parseFloat(b.volume) - parseFloat(a.volume));

        setDiscounts(sortedDiscounts.slice(0, 10));
      } catch (error) {
        console.error("Error fetching discounts: ", error);
      }
    };

    fetchDiscounts();
    const interval = setInterval(fetchDiscounts, 30000); // Update every 10 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [walletProvider]);

  return (
   <div className='leaderboardBox'>
     <div className='cardtxt'>
        <Text className='FullHeading'>Trending Deals</Text>
        </div>
      <div className="marquee-container">
       
        <div className="marquee-content">
          <ul>
            {discounts.map((discount, index) => (
              <li  key={index}>
                <Text className='Heading'>#{index + 1}∙ID: {discount.id.toString()}🔥{discount.formattedVolume}</Text> 
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>

  );
};

export default Leaderboard;
