
import React from 'react';
import img from '../Images/loadinglogo.png'

const Loader = () => {
    
    return (
        
        <div class="loader">
        <div class="fragment-container">
          <img src={img} alt="Loading..." class="fragment" id="fragment1" />
          <img src={img} alt="Loading..." class="fragment" id="fragment2" />
          <img src={img} alt="Loading..." class="fragment" id="fragment3" />
          <img src={img} alt="Loading..." class="fragment" id="fragment4" />
        </div>
      </div>
    );
};

export default Loader;